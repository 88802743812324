angular.module('spApp')
  .factory('youtubeProvider', ['$http', 'youtubeApiKey', function ($http, youtubeApiKey) {

    const YOUTUBE_SEARCH_URL = 'https://www.googleapis.com/youtube/v3/search';
    const YOUTUBE_CLIENT_ID = youtubeApiKey;
    const YOUTUBE_MAX_RESULTS = 5;
    const YOUTUBE_RESULTS_ORDER = 'viewCount';
    const YOUTUBE_SEARCH_TYPE = 'video';
    const YOUTUBE_PART = 'snippet';

    const defaultSearchParams = {
      key: YOUTUBE_CLIENT_ID,
      type: YOUTUBE_SEARCH_TYPE,
      part: YOUTUBE_PART,
      pageToken: "",
      maxResults: YOUTUBE_MAX_RESULTS,
      order: YOUTUBE_RESULTS_ORDER,
      q: ''
    };

    this.search = function (searchString, nextPageToken = '') {
      return $http.get(YOUTUBE_SEARCH_URL, {
        params: angular.extend(defaultSearchParams, {
          pageToken: nextPageToken,
          q: searchString
        })
      });
    };

    return this;
  }]);
