spApp.controller('NewdataController', ['$scope', '$http', 'routeProvider', '$uibModal', function (
  $scope, $http, routeProvider, $uibModal) {
  $scope.assignments = [];
  $scope.loading = false;
  $scope.paging = {
    perPage: 25,
    from: 1,
    to: 25,
    total: 0,
    currentPage: 0,
    lastPage: 0
  };
  $scope.selectedDok = [];
  $scope.selectedFocus = [];
  $scope.selectedGrades = [];
  $scope.selectedLessonTypes = [];
  $scope.selectedStandard = [];
  $scope.selectedStrand = [];
  $scope.selectedSubject = [];
  $scope.selectedSchools = [];
  $scope.selectedDistricts = [];
  $scope.selectedTeachers = [];
  $scope.selectedStates = [];
  $scope.selectedTitle = "";
  $scope.selectedStudent = "";
  $scope.selectedScored = [];
  $scope.selectedDataGroups = [];
  $scope.selectedInterventionGroups = [];
  $scope.fromDate = null;
  $scope.toDate = null;
  $scope.format = 'dd-MMMM-yyyy';
  $scope.altInputFormats = ['M!/d!/yyyy'];
  $scope.assignmentsStudents = [];
  $scope.sort = [];

  $scope.goTo = (page) => {
    $scope.getData(page);
  };

  $scope.$watchGroup(['selectedDok', 'selectedLessonNumbers', 'selectedFocus', 'selectedGrades',
    'selectedLessonTypes', 'selectedPublisher', 'selectedStandard', 'selectedStrand', 'selectedSubject',
    'selectedTitle', 'selectedDueDate', 'selectedScored', 'selectedStudent', 'selectedSchools', 'selectedDistricts', 'selectedTeachers',
    'selectedStates', 'selectedDataGroups', 'selectedInterventionGroups'], () => {
    $scope.getData();
  });

  $scope.getSubject = () => {
    if($scope.assignments) {
      for(let assignment of $scope.assignments){
        if(assignment.type == 'resource'){
          let subject = assignment.item.resource_subjects.pop();
          if(subject){
            assignment.subject = subject.title;
          }
        }
        else{
          for(let resource of assignment.item.resources){
            if(resource.resource_type.title == 'mAuthor'){
              let subject = resource.resource_subjects.pop();
              if(subject){
                assignment.subject = subject.title;
              }
              break;
            }
          }
        }
      }
    }
  };

  //Sorting assignments table
  $scope.$on('sorting', (event, data) => {
    $scope.sort = [data];
    $scope.getData();
    $scope.$broadcast('selectedSort', $scope.sort)
  })

  $scope.getData = (page) => {
    let fromDate, toDate;
    if($scope.fromDate) {
      let fromMonth = $scope.fromDate.getMonth();
      fromDate = moment([$scope.fromDate.getFullYear(), fromMonth, $scope.fromDate.getDate(), 0]).format('LLLL');
    }
    if($scope.toDate) {
      let toMonth = $scope.toDate.getMonth();
      toDate = moment([$scope.toDate.getFullYear(), toMonth, $scope.toDate.getDate(), 0]).format('LLLL');
    }

    let params = {
      perPage: $scope.paging.perPage,
      selectedTitle: $scope.selectedTitle,
      selectedStudent: $scope.selectedStudent ? $scope.selectedStudent.title : '',
      fromDate: fromDate,
      toDate: toDate,
      'selectedScored[]': $scope.selectedScored,
      'selectedDok[]': $scope.selectedDok,
      'selectedLessonNumbers[]': $scope.selectedLessonNumbers,
      'selectedFocus[]': $scope.selectedFocus,
      'selectedGrades[]': $scope.selectedGrades,
      'selectedLessonTypes[]': $scope.selectedLessonTypes,
      'selectedPublisher[]': $scope.selectedPublisher,
      'selectedStandard[]': $scope.selectedStandard,
      'selectedStrand[]': $scope.selectedStrand,
      'selectedSubject[]': $scope.selectedSubject,
      'selectedStates[]':$scope.selectedStates,
      'selectedDistricts[]':$scope.selectedDistricts,
      'selectedSchools[]':$scope.selectedSchools,
      'selectedTeachers[]':$scope.selectedTeachers,
      'selectedGroups[]':$scope.selectedDataGroups,
      'selectedInterventionGroups[]':$scope.selectedInterventionGroups,
      'sort[]': $scope.sort,
    };
    page ? params.page = page : true;
    $scope.loading = true;
    $http.get('/data/filter', {params: params}).then((response) => {
      $scope.assignments = response.data.data;
      $scope.getSubject();
      $scope.getPagingData(response.data);
    }, (data) => {
      console.error(data);
    }).finally(() => {
      $scope.loading = false;
    });
  };

  $scope.getStudentsData = () => {
    $http.get('/users/students').then((response) => {
      $scope.students = response.data;
    }, (data) => {
      console.error(data);
    });
  };

  $scope.getPagingData = (data) => {
    $scope.paging.from = angular.copy(data.from);
    $scope.paging.to = angular.copy(data.to);
    $scope.paging.total = angular.copy(data.total);
    $scope.paging.currentPage = angular.copy(data.current_page);
    $scope.paging.lastPage = angular.copy(data.last_page);
  };

  $scope.init = ({user}) => {
    $scope.getStudentsData();
    $scope.user = user;
  };

  $scope.fromDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(),
    startingDay: 1
  };

  $scope.toDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.fromDateChange = () => {
    $scope.toDateOptions.minDate = $scope.fromDate;
    $scope.getData();
  };

  $scope.toDateChange = () => {
    $scope.fromDateOptions.maxDate = $scope.toDate;
    $scope.getData();
  };

  $scope.fromDateOpen = () => {
    $scope.fromDatePopup.opened = true;
  };

  $scope.toDateOpen = () => {
    $scope.toDatePopup.opened = true;
  };

  $scope.fromDatePopup = {
    opened: false
  };

  $scope.toDatePopup = {
    opened: false
  };

  $scope.clearNameFilter = () => {
    $scope.$broadcast('angucomplete-alt:clearInput', 'name-filter');
  };

  $scope.setScore = (id) => {
    return routeProvider.create('/assignment_score/' + id);
  };

  $scope.showInPlayer = (title, assignmentTitle = null) => {
    let resource = null;

    $http.post('/resources/get-by-title', {title: title}).then((response) => {
      resource = response.data;

      if (assignmentTitle) {
        resource.title = assignmentTitle
      }

        $uibModal.open({
            animation: true,
            templateUrl: '/views/player.html',
            controller: 'PlayerController',
            windowClass: 'player-modal-window',
            resolve: { resource },
        });
    }, (data) => {
        //
    });
  };

  $scope.showStudentInPopup = (assignment) => {
    let resource = {
      id: assignment.id,
      title: assignment.title
    };
    $uibModal.open({
      animation: true,
      templateUrl: '/views/newdata-by-student-popup.html',
      controller: 'NewdataStudentPopupController',
      windowClass: 'fullpage-iframe-modal-window',
      resolve: { resource },
    });
  };

}]);
