spApp.directive('resourceLessonTypeDirective', ['$http', 'loadable', ($http, loadable) => {
  const getLessonTypes =  ($http) =>
      $http.get('/resources/lessonTypes')
        .then(
          response => response.data,
          error => {
            console.error(error)
            return Promise.reject(error)
          }
        );

  const setSelectedLessonTypes = $scope => () => {
    const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));

    if (!urlParams.selectedLessonType) {
      return;
    }

    const { selectedLessonType } = urlParams;

    $scope.selectedLessonTypes = [selectedLessonType];
    $scope.lessonTypes = $scope.lessonTypes.map(item => ({
      ...item,
      selected: item.id == selectedLessonType
    }))
  };

  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-lesson-type-filter.html',
    scope: {
      selectedLessonTypes: '=',
      typesList: '=?lessonTypes',
      loaders: '=?'
    },
    link: function ($scope) {
      const loaded = loadable($scope);

      const promise = $scope.typesList
        ? Promise.resolve($scope.typesList)
        : getLessonTypes($http)

      promise
        .then(data => {
          if(data && data.length < 4) {
            $scope.lessonTypes = [
              ...data,
              {
                'title' : 'Reteach / Intervention Lessons',
                'id' : 'reteachPDF'
              }
            ];
          } else {
            $scope.lessonTypes = data;
          }
        })
        .then(setSelectedLessonTypes($scope))
        .then(() => {
          loaded();
        });

      $scope.lessonTypesChanged = function () {
        $scope.selectedLessonTypes = $scope.lessonTypes.filter(item => item.selected)
          .map(item => item.id);
      };
    }
  };
}]);
