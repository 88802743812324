spApp.controller('UserSettingsController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider',
  function ($scope, $http, $uibModalInstance, alertProvider) {
    $scope.clever = {
      loading: false,
      disabled: true,
      is_connected: true,
    };

    $scope.checkPassword = () => {
      if($scope.password !== $scope.confirmPassword) {
        $scope.changePasswordForm.passwordConfirm.$setValidity('notMatch', false);
      }
      else {
        $scope.changePasswordForm.passwordConfirm.$setValidity('notMatch', true);
      }
    };

    $scope.save = () => {
      $http.post('/users/change_password', {password: $scope.password})
        .then((response) => {
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.connectWithClever = () => {
      if ($scope.clever.disabled) {
        return;
      }

      $scope.clever.loading = true

      setTimeout(() => {
        window.location = $scope.clever.connect_url;
      }, 300);
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $http.get('/users/settings')
      .then(({ data }) => data)
      .then(settings => {
        $scope.clever = {
          ...$scope.clever,
          ...settings.clever,
          disabled: false
        }
      });
  }
]);
