spApp.controller('NavigationController', [
  '$scope', '$http', '$uibModal', 'alertProvider', 'lodash',
  function($scope, $http, $uibModal, alertProvider, lodash) {
    $scope.changePasswordModal = () => {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/settings.html',
        size: 'lg',
        controller: 'UserSettingsController'
      });

      modalInstance.result.then(lodash.noop, lodash.noop);
    };

    $scope.shareApp = () => {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/share-app.html',
        size: 'md',
        controller: 'ShareAppController'
      });

      modalInstance.result.then(lodash.noop, lodash.noop);
    };    

    $scope.pdfPopUp = (e) => {
      if (e.currentTarget && e.currentTarget.href && e.currentTarget.href.indexOf(".pdf") != -1) {
        e.preventDefault();
  
        var resource = {
          id: 0,
          title: e.currentTarget.innerText || e.currentTarget.textContent,
          content: e.currentTarget.href,
          resource_type: { title: 'PDF' }
        };
  
        $uibModal.open({
          animation: true,
          templateUrl: '/views/player.html',
          controller: 'PlayerController',
          windowClass: 'player-modal-window',
          resolve: { resource }
        });
      }
    };
  }
]);
