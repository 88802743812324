spApp.controller('AssignmentAddController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', '$filter', 'options',
  function ($scope, $http, $uibModalInstance, alertProvider, $filter, options) {
    $scope.grades = [];
    $scope.item = null;
    $scope.pagination = {
      page: 1,
      totalPages: 1,
      perPage: 10
    };

    $scope.toDateOptions = {
      formatYear: 'yy',
      formatMonth: 'MMMM',
      formatDay: 'dd',
      minDate: new Date(),
      startingDay: 1,
    };

    $scope.format = 'dd-MMMM-yyyy';
    $scope.altInputFormats = ['M!/d!/yyyy'];


    $scope.dateDueOpened = false;

    // --- pagination
    const setupPaginatedResources = () => {

      if (!$scope.item.resources) {
        return;
      }

      const resources = $scope.item.resources;

      $scope.pagination = {
        ...$scope.pagination,
        page: 1,
        totalPages: Math.ceil(resources.length / $scope.pagination.perPage)
      };
    };

    $scope.getPaginatedResources = () => {
      const begin = ($scope.pagination.page - 1) * $scope.pagination.perPage;
      return $scope.item.resources.slice(begin, begin + $scope.pagination.perPage);
    };

    $scope.hasNextPage = () => $scope.pagination.page < $scope.pagination.totalPages;
    $scope.hasPrevPage = () => $scope.pagination.page > 1;

    $scope.nextPage = () => {
      $scope.pagination.page += 1;
    };

    $scope.prevPage = () => {
      $scope.pagination.page -= 1;
    };
    // --- END pagination

    const getGrade = () => $http.get('/grades/list')
      .then(
        ({ data }) => {
          $scope.grades = data;

          return $scope.grades;
        },
        console.error
      );

    const getLessonSequence = () => $http.get('/instructional-sequences/assignments')
      .then(
        response => $filter('orderBy')(response.data, '-global'),
        console.error
      );

    const getData = () => {
      const itemsPromise = ($scope.assignment.type == "resource")
        ? Promise.resolve([options.item])
        : getLessonSequence()

      const groupsPromise = $http.get('/groups/for-assignment')
        .then(response => response.data);

      return Promise.all([itemsPromise, groupsPromise])
        .then(([items, groups]) => {
          $scope.items = items;
          $scope.item = items.find(({ id }) => id === options.item.id);
          $scope.assignment.title = $scope.item.title;
          $scope.groups = groups;
        });
    };

    const getAssignedResourceFromLessonSequence = () => {
      const assignment = {
        item: $scope.item.id,
        grade: $scope.assignment.grade.id,
        type: $scope.assignment.type
      };

      $http.post('/assignments/assigned-resources', assignment)
        .then((response) => {
          const assignedResources = response.data;

          $scope.item.resources = $scope.item.resources.map(resource => {
            const assignedData = assignedResources.find(({ resource_id }) => resource_id == resource.id);
            const assigned = !!assignedData;

            return {
              ...resource,
              assigned,
              submitted: assigned
                ? !!assignedData.submitted
                : false,
              scored: assigned
                ? !!assignedData.submitted && !!assignedData.scored
                : false
            }
          });
        },
        console.error
      );
    };

    const checkStudents = () => {
      const params = {
        item: $scope.item.id,
        grade: $scope.assignment.grade.id,
        type: $scope.assignment.type
      };

      $http.get('/students/assignments/available', { params })
        .then((response) => {
          $scope.students = response.data.studentsLicence.map(item => item.id);
          $scope.studentsNoLicence = response.data.studentsNoLicence;
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.resetGroup = () => {
      $scope.assignment.studentsType = 'grade';
      $scope.assignment.group = null;
      //$scope.assignment.grade = $scope.grades[0];
    };
    $scope.resetGrade = () => {
      $scope.assignment.studentsType = 'group';
    };

    /*$scope.$watch('assignment.studentsType', (val)=> {
      console.log('val:', val)
      console.log('$scope.assignment:', $scope.assignment)
    })*/
    const init = () => {
      getData()
        .then(getGrade)
        .then(() => {
          $scope.assignment.grade = $scope.grades[0];

          $scope.$watch('item', () => {
            setupPaginatedResources();

            if ($scope.assignment.grade) {
              checkStudents();
            }
          });

          $scope.gradeChanged();

          $scope.$apply();
        });
    };

    $scope.assignment = {
      title: '',
      type: options.type,
      item: $scope.item ? $scope.item.id : null,
      grade: '',
      group: '',
      studentsType: 'grade',
      description: '',
      date_due: new Date(),

    };

    $scope.paging = {};

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      if (!$scope.assignment.group && !$scope.assignment.grade) {
        return;
      }
      const selectedResources = ($scope.assignment.type == 'lesson-sequence')
        ? $scope.item.resources
            .filter(item => item.selected)
            .map(item => item.id)
        : [];

      const data = {
        title: $scope.assignment.title,
        type: $scope.assignment.type,
        item: $scope.item.id,
        grade: $scope.assignment.grade.id,
        group: $scope.assignment.group ? $scope.assignment.group.id : null,
        students: $scope.students,
        description: $scope.assignment.description,
        date_due: $scope.assignment.date_due,
        selectedResources
      }

      $http.post('/assignments', data)
        .then((response) => {
          alertProvider.success('Assignment added.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.gradeChanged = () => {
      checkStudents();
      $scope.resetGroup();

      if($scope.item.resources){
        getAssignedResourceFromLessonSequence();
      }
    };

    init();
  }
]);
