spApp.directive('confirmDirective', ['$http', '$uibModal', function ($http, $uibModal) {
    return {
      transclude: true,
      restrict: 'E',
      templateUrl: '/views/directives/confirm-directive.html',
      scope: {
        name: '@',
        confirmFn: '&',
        type: '@',
        message: '@',
        message2: '@',
        size: '@'
      },
      link: function ($scope) {

        $scope.openModal = () => {
          let modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '/views/confirm.html',
            size: $scope.size ? $scope.size : 'md',
            controller: [
              '$scope', 'message', 'message2', 'name', 'type', '$uibModalInstance',
              ($scope, message, message2, name, type, $uibModalInstance) => {
                $scope.name = name;
                $scope.type = type;
                $scope.message = message;
                $scope.message2 = message2;
                $scope.confirm = () => {
                  $uibModalInstance.close();
                };
                $scope.cancel = () => {
                  $uibModalInstance.dismiss();
                };
              }
            ],
            resolve: {
              name: () => {
                return $scope.name;
              },
              type: () => {
                return $scope.type;
              },
              message: () => {
                return $scope.message;
              },
              message2: () => {
                return $scope.message2;
              },
            }
          });
          modalInstance.result.then(() => {
            $scope.confirmFn();
          }, () => {
          });
        };
      }
    };
  }]);
