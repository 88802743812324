spApp.directive('resourceStrandDirective', ['$http', 'lodash', function ($http, lodash) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-strand-filter.html',
    scope: {
      selectedStrand: '=',
      selectedGrade: '<',
      selectedSubject: '<'
    },
    link: function ($scope) {
      const getData = () => {
        const params = {
          'selectedGrades[]': [$scope.selectedGrade].filter(i => i),
          'selectedSubject[]': $scope.selectedSubject
        };

        $http.get('/resources/strandDomain', { params })
          .then(function (response) {
            $scope.strands = response.data.map(item => ({
              ...item,
              selected: $scope.selectedStrand.includes(item.id)
            }));

            $scope.strandChanged();
          },
          console.error);
      };

      $scope.$watchGroup(['selectedGrade', 'selectedSubject'], lodash.debounce(getData, 500));

      $scope.strandChanged = function () {
        $scope.selectedStrand = $scope.strands
          .filter(({ selected }) => selected)
          .map(({ id }) => id);
      };
    }
  };
}]);
