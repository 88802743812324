spApp.factory('PlayerControls', [
  '$window',
  '$http',
  '$location',
  'lodash',
  function($window, $http, $location, lodash) {
    const Controls = function (playerInstance, frameEl) {
      this.playerInstance = playerInstance;
      this.frameEl = frameEl;
      this.presenter = {
        STANDARD_EVENTS: {
          'ValueChanged': 'vc',
          'Definition': 'de',
          'ItemSelected': 'is',
          'ItemConsumed': 'ic',
          'ItemReturned': 'ir',
          'PageLoaded': 'pl',
          'PageAllOK': 'pa',
          'ShowAnswers': 'sa',
          'HideAnswers': 'ha',
          'Done': 'do',
          'AllAttempted': 'aa',
          'NotAllAttempted': 'naa',
          'LimitedCheck': 'lc',
        },

        onEventReceived: (eventData) => {
          if (eventData == 'ValueChanged') {
            this.saveState();
          }
        },

        run: () => {
          const eventBus = this.playerInstance.getPlayerServices().getEventBus();

          $.each(this.presenter.STANDARD_EVENTS, (name) => {
            eventBus.addEventListener(name, this.presenter);
          });
        },
      };
    }

    Controls.prototype.saveState = function () {
      const state = this.playerInstance.getState();
      const assignmentResource = this.assignmentResource;

      if (!assignmentResource || assignmentResource.submitted) {
        return Promise.resolve();
      }

      const playerServices = this.playerInstance.getPlayerServices();

      assignmentResource.current_page = playerServices.getCurrentPageIndex();
      assignmentResource.player_state = state;

      return $http.post(`/student/lesson/${assignmentResource.id}/save`, assignmentResource)
        .catch(console.error);
    };

    Controls.prototype.getScore = function () {
      const resultScore = {
        score: 0,
        maxScore: 0,
      };

      const playerServices = this.playerInstance.getPlayerServices();

      const model = playerServices.getPresentation();
      const scoreService = playerServices.getScore();

      for (let i = 0; i < model.getPageCount(); i++) {
        const page = model.getPage(i);

        if (!page.isReportable()) {
          continue;
        }

        const scoreArray = scoreService.getPageScoreById(page.getId());

        if (page.isVisited()) {
          resultScore.score += scoreArray.score;
          resultScore.maxScore += scoreArray.maxScore;
        }
        else {
          return false;
        }
      }

      return resultScore;
    };

    Controls.prototype.load = function (assignmentResource, role) {
      this.assignmentResource = assignmentResource;

      const state = this.assignmentResource.player_state;
      const currentPage = (role == 'Teacher')
        ? 0
        : this.assignmentResource.submitted
        ? 0
        : this.assignmentResource.current_page;

      const content = this.assignmentResource.resource.content;

      if (state) {
        this.playerInstance.setState(state);
      }

      this.playerInstance.setPages(lodash.range(1, 101).join(','));
      this.playerInstance.load(`${Config.playerUrl}/resources/${content}/pages/main.xml`, currentPage);
    };

    Controls.prototype.updateUi = function () {
      this.presenter.run();
      _setEditable.call(this);
    };

    Controls.prototype.checkAnswers = function () {
      const playerServices = this.playerInstance.getPlayerServices();
      const commands = playerServices.getCommands();

      commands.checkAnswers();
    };

    const _setEditable = function () {
      const assignmentResource = this.assignmentResource;
      const frame = this.frameEl;

      if (assignmentResource && assignmentResource.submitted) {
        setTimeout(function() {
          const $body = angular.element(frame.contentDocument.querySelector('body'));

          $body.find('#tinymce')
            .attr('contenteditable', false);
        }, 2000);
      }
    };

    return Controls;
  }
]);
