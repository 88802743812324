angular.module('spApp')
  .factory('loadable', [
    'lodash',
    (lodash) => ($scope) => {
      if (false === 'loaders' in $scope) {
        return lodash.noop;
      }

      const loaderId = { ts: +new Date() };
      $scope.loaders.push(loaderId)

      return () => {
        const index = $scope.loaders.indexOf(loaderId);
        $scope.loaders.splice(index, 1);
      }
    }
  ]
)
