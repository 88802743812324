spApp.directive('districtDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/district-filter.html',
    scope: {
      selectedStates: '=',
      selectedDistricts: '='
    },
    link: function ($scope) {
      $scope.$watchGroup(['selectedStates'], () => {
        $scope.getDistrictData();
      });

      $scope.getDistrictData = () => {
        const params = {
          'states[]': $scope.selectedStates
        };

        $http.get('/districts/list', {params: params}).then((response) => {
          $scope.districts = response.data;
        });
      }

      $scope.getDistrictData();
      
      $scope.districtChanged = function () {
        $scope.selectedDistricts = $scope.districts.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
