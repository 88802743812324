spApp.directive('selectGradeDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: '/views/directives/select-grade-directive.html',
      scope: {
        grades: '<',
        selectedGrade: '=',
      },
      link: function ($scope) {
        const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));
        const selectedGrade = urlParams.selectedGrade && parseInt(urlParams.selectedGrade, 10);

        if (selectedGrade) {
          $scope.selectedGrade = selectedGrade
        }
      }
    }
  }
]);
