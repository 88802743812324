spApp.controller('GroupController', [
  '$scope',
  '$http',
  'squareTile',
  'Groups.EditModal',
  'lodash',
  'alertProvider',
  '$window',
  function ($scope, $http, squareTile, editModal, lodash, alertProvider, $window) {
    $scope.groups = [];
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0
    };
    $scope.clever = {
      loading: false
    };

    $scope.selectedSubject = [];
    $scope.selectedGrades = [];
    $scope.selectedTeachers = [];
    $scope.selectedSchools = [];
    $scope.selectedTitle = "";
    $scope.selectedStudent = "";

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    $scope.$watchGroup(['selectedSubject', 'selectedStudent', 'selectedGrades', 'selectedTitle', 'selectedTeachers', 'selectedSchools'], () => {
      $scope.getData();
    });

    $scope.getData = (page) => {
      const params = {
        selectedTitle: $scope.selectedTitle ? $scope.selectedTitle.title : '',
        selectedStudent: $scope.selectedStudent ? $scope.selectedStudent.title : '',
        'selectedGrades[]': $scope.selectedGrades,
        'selectedSubject[]': $scope.selectedSubject,
        'selectedTeachers[]': $scope.selectedTeachers,
        'selectedSchools[]': $scope.selectedSchools,
        perPage: $scope.paging.perPage,
        page: page ? page : 1
      };

      $http.get('/groups/filter', {params: params}).then((response) => {
        $scope.groups = response.data.data;
        console.log('groups ---> ', $scope.groups);
        $scope.getPagingData(response.data);
      }, (data) => {
        console.error(data);
      });

    };

    $scope.getPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.getStudentsData = () => {
      $http.get('/users/students').then((response) => {
        $scope.students = response.data;
      }, (data) => {
        console.error(data);
      });
    };

    $scope.clearNameFilter = () => {
      $scope.$broadcast('angucomplete-alt:clearInput', 'name-filter');
    };

    $scope.clearGroupNameFilter = () => {
      $scope.$broadcast('angucomplete-alt:clearInput', 'group-name-filter');
    };

    $scope.addGroupModal = (type, group) => {
      editModal(type, group)
        .then(
          () => {
            $scope.getData();
          },
          lodash.noop
        );
    };

    $scope.init = () => {
        $scope.getData();
        $scope.getStudentsData();
        $scope.squareTile = squareTile;
    };

    $scope.syncWithClever = () => {
      $scope.clever.loading = true;

      $http.post('/_clever/sync/groups')
        .then(response => {
          $window.location.reload();
        })
        .catch(error => {
          $scope.clever.loading = false;
          alertProvider.responseError(error);
        });
    };
}]);
