spApp.filter('stateFilter', [() => {
  return (inputArray, state_id) => {
    if (!state_id) {
      return inputArray;
    }
    let data = [];
    angular.forEach(inputArray, (item) => {
      if (item.district.state_id === state_id) {
        data.push(item);
      }
    });
    return data;
  };
}]);