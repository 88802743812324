spApp.controller('LessonController', ['$scope', '$http','$window', function ($scope, $http, $window) {
    $scope.studentAssignments={};
    $scope.types = [];
    $scope.status = "toDo";
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0
    };    
    
    $scope.isAssessment = resource => typeof resource.resource_lesson_types !== 'undefined' ? resource.resource_lesson_types.some(item => item.title === 'Assessment') : false;
    $scope.isPerformanceLesson = resource => typeof resource.resource_lesson_types !== 'undefined' ? resource.resource_lesson_types.some(item => item.title === 'Performance Lesson') : false;
    $scope.isReteachPdf = resource => typeof resource.resource_type !== 'undefined' ? resource.resource_type.title === 'PDF' && !$scope.isPerformanceLesson(resource) : false;

    $scope.getData = (page) => {
        let types = $scope.types.map((item) => item.id);
        let params = {
          'types[]': types,
          status: $scope.status,
          perPage: $scope.paging.perPage
        };
        page ? params.page = page : true;

        $http.get('/student/assignments', {params: params}).then((response) => {
          $scope.studentAssignments = response.data.data;
          $scope.getPagingData(response.data);
          colourLessonSets();
        }, (data) => {
          console.error(data);
        });
    };

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    $scope.openLesson = (assignmentStudentResource) => {
      if(angular.isObject(assignmentStudentResource)) {
        $window.location.href = 'lesson/'+ assignmentStudentResource.assignment_student_id + '/' + assignmentStudentResource.id ;
      }
    };

    $scope.getPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.showOnly = (status) => {
        $scope.status = status;
        $scope.getData()
    };

    $scope.init = (types) => {
      $scope.types = types;
      $scope.getData();
    };

  let colourLessonSets = () => {
    let colourSetNumber = 0;
    let initialLessonSetId = 1;
    angular.forEach($scope.studentAssignments, function (resource) {
      if (resource['resource']['lesson_set_id'] !== initialLessonSetId) {
        if (colourSetNumber === 7) {
          colourSetNumber = 0;
        }

        colourSetNumber++;
        initialLessonSetId = resource['resource']['lesson_set_id'];
      }
      resource.colour = colourSetNumber;
    });
  }
  }]);
  