spApp.controller('StudentEditController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', 'student', 'user', '$window', 'toastr',
  function ($scope, $http, $uibModalInstance, alertProvider, student, user, $window, toastr) {
    $scope.user = user;
    $scope.disabled = false;
    $scope.editMode = !!(student && student.id);
    $scope.MathTeachers = [];
    $scope.ELATeachers = [];

    $scope.student = {
      ...student,
      teachers: student.teachers || []
    };

    const filterStudentTeachers = (subjectName) => $scope.student.teachers
      .filter((teacher) => teacher.subjects_conducted.some(subject => (
        subject.title === subjectName && teacher.pivot.subject_id === subject.id
      )))
      .map(item => item.id);

    const filterTeachers = (teachers) => {
      const filterBySubject = subjectName => teacher => teacher.subjects_conducted.some(
        ({ title }) => title === subjectName
      );

      $scope.MathTeachers = teachers.filter(filterBySubject('Math'))
        .map(item => ({
          ...item,
          selected: $scope.student.MathTeachers.indexOf(item.id) !== -1
        }));

      $scope.ELATeachers = teachers.filter(filterBySubject('ELA'))
        .map(item => ({
          ...item,
          selected: $scope.student.ELATeachers.indexOf(item.id) !== -1
        }));
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      const selectedMathTeachers = $scope.MathTeachers
        .filter(item => item.selected)
        .map(item => item.id);

      const selectedELATeachers = $scope.ELATeachers
        .filter(item => item.selected)
        .map(item => item.id);

      $scope.student.MathTeachers = selectedMathTeachers;
      $scope.student.ELATeachers = selectedELATeachers;

      $http.post('/students', $scope.student)
        .then((response) => {
          alertProvider.success('Student saved.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.getData = () => {
      $http.get('/states/list')
        .then((response) => {
          $scope.states = response.data;
        })
        .catch(console.error);

      $http.get('/schools/list')
        .then((response) => {
          $scope.schools = response.data;
        })
        .catch(console.error);

      $http.get('/districts/list')
        .then((response) => {
          $scope.districts = response.data;
        })
        .catch(console.error);

      $http.get('/grades/list')
        .then((response) => {
          $scope.grades = response.data;
        })
        .catch(console.error);

      if ($scope.user.role !== 'Teacher') {
        $http.get('/teachers/list', { params: { school_id: $scope.student.school_id } })
          .then(({ data }) => {
            filterTeachers(data);
          })
          .catch(console.error);
      }
    };

    $scope.activate = () => {
      $http.put(`/students/${$scope.student.id}/status`)
        .then((response) => {
          alertProvider.success('Student activated.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.deactivate = () => {
      $http.delete(`/students/${$scope.student.id}/status`)
        .then((response) => {
          alertProvider.success('Student deactivated.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.resetSchool = () => {
      $scope.student.school_id = 0;
    };

    $scope.hasActiveLicence = (subjectName, { active_licences }) => active_licences.some(
      ({ subject, active }) => (subject.title === subjectName && active)
    );

    if($scope.user.role === 'Teacher' || $scope.student.from_clever){
      $scope.disabled = true;
    }

    $scope.student.MathTeachers = filterStudentTeachers('Math');
    $scope.student.ELATeachers = filterStudentTeachers('ELA');

    $scope.getData();

    $scope.getDeleteAlertText = () => {
      return `Are you sure you want to delete the student? This will delete all selected user data and cannot be recovered.`;
    }

    $scope.destroy = () => {
      $http.delete(`/students/${$scope.student.id}`)
        .then(() => {
          toastr.success('Removed student');
          $window.location.reload();
        })
        .catch(error => {
          alertProvider.responseError(error);
        });
    };
  }
]);
