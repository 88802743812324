spApp.directive('playerFrame', [
  '$window',
  function ($window) {

    const resizeFrame = ($scope, message) => {

      /*$scope.parentWidth = 0;
      $scope.pageLoaded = false;

      const [, width, height] = message.match(/(\d+);(\d+)/);

        const parentEl = document.getElementsByClassName("player-preview");
        $scope.parentWidth = parentEl[0] ? parentEl[0].offsetWidth : $scope.innerWidth;

      if (parseInt(width, 10) && parseInt(height, 10)) {
        const widthOriginal = parseInt(width || $scope.parentWidth, 10);
        const heightOriginal = parseInt(height, 10);
        const percent = $scope.parentWidth / widthOriginal;
        $scope.playerDimensions = {
          originalWidth: widthOriginal,
          originalHeight: heightOriginal,
          adaptedHeight: Math.round(height * percent)
        };
        // manually $digest required as resize event is outside of angular
        $scope.$digest();
      }*/

    };

    const adjustFrameHeightOnResized = ($scope, $element) => {

     /* if (document.getElementsByClassName('student-home-content').length) {
        const parentEl = document.getElementsByClassName("player-preview");
        $scope.parentWidth = parentEl[0] ? parentEl[0].offsetWidth : $scope.innerWidth;
      } else {
        $scope.parentWidth = document.getElementById('_icplayer')
          ? document.getElementById('_icplayer').offsetWidth
          : $window.innerWidth;
      }

      if (!$scope.playerDimensions) {
        $scope.playerDimensions = {
          originalWidth: $scope.player.width,
          originalHeight: $scope.player.height,
        }
      }

      const percent = $scope.parentWidth / ($scope.playerDimensions.originalWidth || $scope.parentWidth);
      $scope.playerDimensions.adaptedHeight = Math.round(($scope.playerDimensions.originalHeight || $window.innerHeight)* percent);
      // manually $digest required as resize event is outside of angular
      $scope.$digest();*/
    }

    const onMessage = ($scope, $element) => message => {

      if (!message.data) {
        return;
      }

      if (/RESIZE/.test(message.data)) {
        resizeFrame($scope, message.data);
      }

      if (/PAGE_LOADED/.test(message.data)) {
        $scope.$emit('playerFrame:pageLoaded');
      }
    };
    return {
      restrict: 'E',
      templateUrl: '/views/directives/player-frame-directive.html',
      scope: {
        resource: '<',
        standalone: '<?'
      },
      link: function ($scope, $element) {
        // adjust frame height on resize considering the scaling factor
        /*angular.element($window).bind('resize', function () {

          if (document.getElementsByClassName('student-home-content').length) {
            const parentEl = document.getElementsByClassName("player-preview");
            $scope.parentWidth = parentEl[0] ? parentEl[0].offsetWidth : $scope.innerWidth;
          } else {
            $scope.parentWidth = document.getElementById('_icplayer')
              ? document.getElementById('_icplayer').offsetWidth
              : $window.innerWidth;
          }

          adjustFrameHeightOnResized($scope, $element);
          // manually $digest required as resize event is outside of angular
          $scope.$digest();
        });*/


        $scope.standalone = ('standalone' in $scope)
          ? $scope.standalone
          : false;

        $scope.player = {
          width: 965,
          height: 600
        };

        $scope.onFrameLoaded = () => {
          // make fullheight iframe on page
          let frames = $element.find('iframe');
          let prevSize = 0;
          let prevSize2 = 0;
          let resizeTimer = null;
          if(frames && frames[0]) {
            if(parseInt(frames[0].contentWindow.document.body.scrollHeight) > 100) {
              prevSize = frames[0].contentWindow.document.body.scrollHeight;
              $scope.player.height = frames[0].contentWindow.document.body.scrollHeight;
              $scope.$digest();
            }
            // on iframe resize event
            frames[0].contentWindow.addEventListener('resize', function(){
              // prevent very frequent resizing
              if(resizeTimer) {
                clearTimeout(resizeTimer);
              }

              resizeTimer = setTimeout(() => {
                let currentSize = frames[0].contentWindow.document.body.scrollHeight;
                if(parseInt(currentSize) > 100) { // change size for iframe with content
                  if(prevSize != currentSize && prevSize2 != currentSize) { // prevent infinity resizing loop
                    prevSize2 = prevSize;
                    $scope.player.height = prevSize = currentSize;
                    $scope.$digest();
                  }
                }
              }, 300);
            });
          }

          // Apparently some devices (I'm looking at you iPad!)
          // have problem with the frame loading.
          // The script needs to wait a bit so that the iPad will
          // be able to grab the instance of the player.
          setTimeout(() => {
            const frames = $element.find('iframe')
            const player = frames[0].contentWindow.player;
            $scope.$emit('playerFrame:loaded', player, frames[0]);

            if ($scope.standalone) {
              player.load(`${Config.playerUrl}/resources/${$scope.resource.content}/pages/main.xml`)
            }
          }, 500)
        };

        const listener = onMessage($scope, $element);

        $scope.$watch('resource', () => {
          $scope.frameUrl = `/player/?ver=${+new Date()}&path=${$scope.resource.content}`;
        });

        window.addEventListener('message', listener, false);

        $scope.$on('destroy', () => {
          window.removeEventListener('message', listener);
        });
      }
    }
  }
]);
