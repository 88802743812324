spApp.factory('printTeacherPdf', [
  '$http',
  function ($http) {
    const openFirstPage = ($scope, playerFrameEl, playerInstance) => {
      return new Promise(resolve => {

        if (playerInstance.getPlayerServices().getCurrentPageIndex() === 0) {
            return resolve();
        }

        const deregisterListener = $scope.$on('playerFrame:pageLoaded', () => {
          deregisterListener();
          resolve();
        });

        playerFrameEl.contentDocument.querySelector('.table-of-contents-list a')
          .click();
      });
    };

    const cloneFrameContents = (playerFrameEl) => {
      const $pageEl = $(playerFrameEl.contentDocument.querySelector('.ic_page')).clone();

      $pageEl.find('img').remove();
      $pageEl.find('.doublestate-button-element').remove();

      return $pageEl;
    };

    const exportHtml = ($pageEl) => {
      const year = Date.prototype.getFullYear
        ? new Date().getFullYear()
        : 2019;

      let html = `<html>
        <head>
          <style>
            @page { margin: 25px 50px 100px 50px; }
            footer {position: fixed; bottom: -60px; left: 0px; right: 0px; height: 25px;color: dimgray;font-style: italic;}
            p { page-break-after: always; }
            p:last-child { page-break-after: never; }
          </style>
        </head>
        <body>

        <footer>© Copyright Learning Plus Associates ${year}</footer>
        <main>
        <h4 style="text-align: center;">Standards Plus Digital</h4>`;

      $pageEl.children()
        .each(function () {
          html += this.innerHTML;
        });

      html += `</main>
        </body>
        </html>`;

      return html;
    }

    return function ($scope, { player, title }) {
      const { frameEl, instance } = player;

      return new Promise((resolve, reject) => {
        if (!frameEl) {
          return reject(new Error('playerFrameEl does not exist'));
        }

        return openFirstPage($scope, frameEl, instance)
          .then(() => {
            const $page = cloneFrameContents(frameEl);
            const html = exportHtml($page);

            return $http.post('/resource/page/save', { html, title })
          })
          .then(response => {
            const name = response.data;

            window.location.href = `/resource/print/${name}`;
          });
      });
    };
  }
])
