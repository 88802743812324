spApp.directive('importUserDirective', ['$http', 'alertProvider', 'toastr', function ($http, alertProvider, toastr) {
  return {
    restrict: 'E',
    templateUrl: '/views/directives/import-users-directive.html',
    scope: {
      showType: '@',
      isSchoolAdmin: '@',
      isDistrictAdmin: '@',
      isItAdmin: '@',
    },
    link: function ($scope) {
      $scope.loading = false;
      $scope.error = false;
      $scope.save = (file, type) => {
        $scope.loading = true;
        $scope.error = false;

        const fd = new FormData();
        fd.append('file',file);
        fd.append('type', type);

        const data = {
          withCredentials : false,
          headers : {
            'Content-Type' : undefined
          },
          transformRequest : angular.identity
        };

        $http.post('/import/users', fd, data)
          .catch((response) => {
            alertProvider.responseError(response)
            $scope.loading = false;
            $scope.error = true;
          })
          .finally(function () {
            if($scope.error !== true) {
              location.reload();
            }
            $scope.loading = false;
          });
      }
    }
  };
}]);
