spApp.directive('resourceFocusDirective', ['$http', 'lodash', function ($http, lodash) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-focus-filter.html',
    scope: {
      selectedFocus: '=',
      selectedStrand: '<',
      selectedGrade: '<',
      selectedSubject: '<'
    },
    link: function ($scope) {
      const getData = () => {
        const params = {
          'selectedGrades[]': [$scope.selectedGrade].filter(i => i),
          'selectedSubject[]': $scope.selectedSubject,
          'selectedStrand[]': $scope.selectedStrand
        };

        $http.get('/resources/foci', { params })
          .then(
            function (response) {
              $scope.foci = response.data.map(item => ({
                ...item,
                selected: $scope.selectedFocus.includes(item.id)
              }));

              $scope.focusChanged();
            },
            console.error
          );
      }

      $scope.$watchGroup(
        [
          'selectedStrand', 'selectedGrade', 'selectedSubject'
        ],
        lodash.debounce(getData, 500)
      );

      $scope.focusChanged = function () {
        $scope.selectedFocus = $scope.foci
          .filter(({ selected }) => selected)
          .map(({ id }) => id);
      };
    }
  };
}]);
