spApp.directive('datagroupDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/datagroup-filter.html',
    scope: {
      selectedTeachers: '=',
      selectedDataGroups: '=',
      groupParams: '=',
      userrole: '='
    },
    link: function ($scope) {
      $scope.$watchGroup(['selectedTeachers'], () => {
        $scope.getGroupData();
      });

      $scope.getGroupData = () => {
        let params = { };
        if($scope.selectedTeachers) {
          params = {
            'type': $scope.groupParams.type,
            'teachers[]': $scope.selectedTeachers
          };
        } else {
          params = {
            'type': $scope.groupParams.type
          };
        }

        $scope.groups = [];        
        $http.get('/groups/list', {params: params}).then(function (response) {
          $scope.groups = response.data;
        }, function (data) {
          console.error(data);
        });      
      }

      if($scope.userrole === 'Teacher') {        
        $scope.getGroupData();
      }

      $scope.groupChanged = function () {
        $scope.selectedDataGroups = $scope.groups.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };      

      $scope.getGroupData();
    }
  };
}]);
