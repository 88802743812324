spApp.controller('TrialSignupController', [
  '$scope',
  '$element',
  '$window',
  function ($scope, $element, $window) {
    $scope.loaded = false;

    const $iframe = $('iframe', $element);

    $iframe.ready(function () {
      $scope.loaded = true;
      $scope.$apply();

      // Due to the DOM restriction it should not be possible to retrieve
      // the contentWindow of the IFRAME. It's only possible when the source
      // of the frame points to the same domain (here: gateway2achieve.com).
      //
      // When it's not possible to get the href we assume that the user is still on the form.
      // After redirect they're going to be redirected to the main page.
      try {
        const href = this.contentWindow.location.href;
        $window.location.href = '/';
      } catch (e) {
        // do nothing.
      }
    });
  }
]);
