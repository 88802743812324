spApp.controller('TextResourceEditController', [
  '$scope', '$uibModalInstance', 'resource',
  function ($scope, $uibModalInstance, resource) {
    $scope.resource = angular.copy(resource);
    $scope.options = {
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['alignment', ['ul', 'ol']]
      ]
    };
    $scope.save = () => {
      $uibModalInstance.close($scope.resource.content);
    };
    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };
  }
]);
