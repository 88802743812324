spApp.controller('LessonSequencesController', [
  '$scope', '$http', '$uibModal', 'alertProvider', 'lodash',
  function($scope, $http, $uibModal, alertProvider, lodash) {
    $scope.lessonSequences = [];
    $scope.selectedDok = [];
    $scope.selectedLessonNumbers = [];
    $scope.selectedFocus = [];
    $scope.selectedGrades = [];
    $scope.selectedLessonTypes = [];
    $scope.selectedPublisher = [];
    $scope.selectedStandard = [];
    $scope.selectedStrand = [];
    $scope.selectedSubject = [];
    $scope.selectedCategories = [];
    $scope.selectedSchools = [];
    $scope.selectedTeachers = [];
    $scope.selectedSequenceTypes = [];
    $scope.selectedTitle = '';
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0,
    };

    $scope.sortableOptions = {
      placeholder: 'resource',
      connectWith: '.resources-sortable-list',
      update: (e, ui) => {
        if (ui.item.sortable.droptarget[0].id === 'resources-sortable-list') {
          ui.item.sortable.cancel();
        }
      },
      stop: (e, ui) => {
        let folderIdString = ui.item.sortable.droptarget[0].id.split('-');
        let folderId = folderIdString[1];
        let lessonSequenceId = ui.item.sortable.model.id;

        $scope.addLessonSequenceToFolder(folderId, lessonSequenceId);
      },
    };

    $scope.addLessonSequenceToFolder = (folderId, lessonSequenceId) => {
      $http.post('/folders/' + folderId + '/items/' + lessonSequenceId +
        '/lesson-sequence').then((response) => {
        //
      }, (data) => {
        //
      });
    };

    $scope.clearFilter = () => {
      $scope.selectedTitle = '';
    };

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    $scope.$watchGroup([
      'selectedDok',
      'selectedLessonNumbers',
      'selectedFocus',
      'selectedGrades',
      'selectedLessonTypes',
      'selectedPublisher',
      'selectedStandard',
      'selectedStrand',
      'selectedSubject',
      'selectedTitle',
      'selectedSchools',
      'selectedTeachers',
      'selectedCategories',
      'selectedSequenceTypes'], function() {
      $scope.getData();
    });

    $scope.getData = (page) => {
      let params = {
        perPage: $scope.paging.perPage,
        selectedTitle: $scope.selectedTitle,
        'selectedDok[]': $scope.selectedDok,
        'selectedLessonNumbers[]': $scope.selectedLessonNumbers,
        'selectedFocus[]': $scope.selectedFocus,
        'selectedGrades[]': $scope.selectedGrades,
        'selectedLessonTypes[]': $scope.selectedLessonTypes,
        'selectedPublisher[]': $scope.selectedPublisher,
        'selectedStandard[]': $scope.selectedStandard,
        'selectedStrand[]': $scope.selectedStrand,
        'selectedSubject[]': $scope.selectedSubject,
        'selectedSchools[]': $scope.selectedSchools,
        'selectedTeachers[]': $scope.selectedTeachers,
        'selectedSequenceTypes[]': $scope.selectedSequenceTypes,
        'selectedSequenceCategories[]': $scope.selectedCategories,
      };

      page ? params.page = page : true;
      $http.get('/instructional-sequences/filter', {params: params}).
        then((response) => {
          $scope.lessonSequences = response.data.data;
          $scope.getPagingData(response.data);
        }, (data) => {
          //
        });
    };

    $scope.addLessonSequence = () => {
      const modal = $uibModal.open({
        animation: true,
        templateUrl: '/views/add-lesson-sequence.html',
        controller: 'LessonSequencesAddController',
        size: 'md',
        resolve: {
          authUser: $scope.authUser
        }
      });

      modal.result.then(
        lodash.noop,
        lodash.noop
      );
    };

    $scope.getPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.deleteLessonSequence = (lessonSequenceId) => {
      $http.delete('/instructional-sequences/' + lessonSequenceId).then((response) => {
        $scope.lessonSequences = $scope.lessonSequences.filter((element) => {
          return parseInt(element.id) !== parseInt(lessonSequenceId);
        });
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    };

    $scope.init = (user) => {
      $scope.getData();
      $scope.authUser = user;
    };

    $scope.checkOwner = (userId, lessonSequence) => {
      return userId == lessonSequence.owner_id;
    };

    $scope.previewSequence = (lessonSequence) => {
      window.location = `/instructional-sequences/${lessonSequence.id}`;
    };
  }]);
