spApp.filter('sortRubrics', [
  () => {
    return (input) => {
      const copy = [...input];

      copy.sort((left, right) => {
        const leftPageNo = left.content;
        const rightPageNo = right.content;

        return (Number.isInteger(leftPageNo) && Number.isInteger(rightPageNo))
          ? leftPageNo - rightPageNo
          : leftPageNo.localeCompare(rightPageNo);
      });

      return copy;
    }
  },
]);
