spApp.controller('LessonSequenceBuilderController', [
  '$scope',
  '$http',
  '$uibModal',
  'toastr',
  'alertProvider',
  'youtubeProvider',
  '$window',
  'uiSortableMultiSelectionMethods',
  'authUser',
  'SequenceCategories.api',
  'lodash',
  function ($scope, $http, $uibModal, toastr, alertProvider, youtubeProvider, $window, uiSortableMultiSelectionMethods, authUser, SequenceCategoriesApi, lodash) {
    $scope.lessonSequence = {};
    $scope.lessonSets = [];
    $scope.pdfs = [];
    $scope.capstonepdfs = [];
    $scope.learnetic = [];
    $scope.assessments = [];
    $scope.textResource = {};
    $scope.view = 'lesson-set';
    $scope.youtubeSearchString = '';
    $scope.youtubeResults = [];
    $scope.nextPageToken = '';
    $scope.subjects = [];
    $scope.grades = [];
    $scope.standards = [];
    $scope.strandDomains = [];
    $scope.activePreviewItem = null;
    $scope.oldResource = null;
    $scope.countAllResources = 0;
    $scope.hideEditPanel = false;
    $scope.loading = false;
    $scope.sequenceCategories = [];
    $scope.authUser = authUser;

    $scope.filters = {
      search: null,
      subject: null,
      grade: null,
      strandDomain: null,
      standard: null,
      selectedPdfTitle: null,
      selectedCapstonePdfTitle: null,
      selectedLearneticTitle: null,
      selectedAssessmentTitle: null,
      assessmentsStandard: null,
      assessmentsDomain: null,
      assessmentsGrade: null,
      assessmentsSubject: null
    };

    $scope.previewVisible = false;

    let getData = (lessonId) => {
      $http.get('/instructional-sequences/' + lessonId).then((response) => {
        $scope.lessonSequence = response.data;
        $scope.countAllResources = 0;
        if(typeof $scope.lessonSequence.resources != 'undefined') {
          $scope.countAllResources = $scope.lessonSequence.resources.length;
        }
        colourLessonSets();
        getAssignedResourceFromLessonSequence();
        if (typeof $scope.lessonSequence.resources != 'undefined' && $scope.lessonSequence.resources.length) {
          $scope.hideEditPanel = true;
        }
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      getLessonSets();

      $http.get('/resources/subjects').then((response) => {
        $scope.subjects = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/grades').then((response) => {
        $scope.grades = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/strandDomain').then((response) => {
        $scope.strandDomains = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/standards').then((response) => {
        $scope.standards = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/assessments').then((response) => {
        $scope.assessments = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/pdfs').then((response) => {
        $scope.pdfs = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/capstone-pdfs').then((response) => {
        $scope.capstonepdfs = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

      $http.get('/resources/learnetic').then((response) => {
        $scope.learnetic = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });

    };

    let getLessonSets = () => {
      $http.get('/lesson-sets', {
        params: $scope.filters
      }).then((response) => {
        $scope.lessonSets = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    let getFilteredPdf = () => {
      $http.get('/resources/pdfs', {
        params: $scope.filters
      }).then((response) => {
        $scope.pdfs = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    let getFilteredCapstonePdf = () => {
      $http.get('/resources/capstone-pdfs', {
        params: $scope.filters
      }).then((response) => {
        $scope.capstonepdfs = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    let getFilteredLearnetic = () => {
      $http.get('/resources/learnetic', {
        params: $scope.filters
      }).then((response) => {
        $scope.learnetic = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    let getFilteredAssessments = () => {
      $http.get('/resources/assessments', {
        params: $scope.filters
      }).then((response) => {
        $scope.assessments = response.data;
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    $scope.getMissingTiles = () => {
      let length = 0;
      if ($scope.lessonSequence.resources) {
        length = $scope.lessonSequence.resources.length;
      }
      let inRow = 6;
      return new Array(inRow - (length % inRow));
    };

    $scope.sortableOptions = uiSortableMultiSelectionMethods.extendOptions({
      connectWith: '.lesson-sequence-tiles',
      placeholder: 'being-dragged',
      items: '.draggable-item:not(.blocked)',
      multiSelectOnClick: true,
      cancel: '.disable-sort-item',
      start: (e, ui) => {
        let sourceModel = angular.copy($scope.lessonSequence.resources);
        ui.item.data('sourceModel', sourceModel);
      },
      update: (e, ui) => {
        ui.item.data('dropIndex', ui.item.sortable.dropindex);
        ui.item.data('model', ui.item.sortable.model);
      },
      stop: (e, ui) => {
        const type = ui.item.data('type')
        const id = ui.item.attr('id');

        if ($scope.lessonSequence.resources.length > 1) {
          if (type && type !== 'youtube') {
            $scope.loading = true;
          }

          let sourceModel = ui.item.data('sourceModel');
          let dropIndex = ui.item.data('dropIndex');
          let model = ui.item.data('model');
          let lessonSetId = [];
          let numberOfLessons = 0;
          let indexInLessonSetId = null;
          let id = ui.item.attr('id');

          $scope.countAllResources = $scope.lessonSequence.resources.length;
          if (sourceModel === undefined) {
            sourceModel = angular.copy($scope.lessonSequence.resources);
            sourceModel.splice(dropIndex, 1);
          }

          for (let i = 0; i < $scope.lessonSequence.resources.length; i++) {
            lessonSetId.push($scope.lessonSequence.resources[i]['lesson_set_id']);
          }

          if (model !== undefined && model['lesson_set_id'] !== null) {
            angular.forEach(lessonSetId, function (value) {
              if (value === model['lesson_set_id']) {
                numberOfLessons++;
              }
            });

            indexInLessonSetId = lessonSetId.indexOf(model['lesson_set_id']);
          }

          if (dropIndex === 0 || dropIndex + 1 === $scope.lessonSequence.resources.length || (model !== undefined && model['lesson_set_id'] === null && lessonSetId[dropIndex + 1] !== lessonSetId[dropIndex - 1]) || (numberOfLessons > 0 && indexInLessonSetId !== null && lessonSetId[indexInLessonSetId - 1] !== lessonSetId[indexInLessonSetId + numberOfLessons]) || (numberOfLessons > 0 && indexInLessonSetId !== null && (lessonSetId[indexInLessonSetId - 1] === null || lessonSetId[indexInLessonSetId + numberOfLessons] === null)) || (lessonSetId[dropIndex] === null && lessonSetId[dropIndex + 1] === null)) {

          } else {
            toastr.error('Please don\'t split the lesson set.');
            for (let i = 0; i < sourceModel.length; i++) {
              sourceModel[i]['selected'] = false;
            }
            $scope.lessonSequence.resources = sourceModel;
            clearItems();
            addLessonSetToList(id);
            $scope.loading = false;
            return;
          }
        }

        for (let i = 0; i < $scope.lessonSequence.resources.length; i++) {
          $scope.lessonSequence.resources[i]['selected'] = false;
        }

        if (type === 'lesson-set' || type === 'youtube') {
          // fix for endPosition, max endPosition is end of array
          // we subtract 1 because set is already added
          let endPosition = Math.min(ui.item.sortable.dropindex, $scope.lessonSequence.resources.length - 1);

          // split sequence into two parts, based on insert position
          let firstPart = $scope.lessonSequence.resources.slice(0, endPosition);

          // we skip first element in second part of sequences, because it is added set, which should be removed
          let secondPart = $scope.lessonSequence.resources.slice(endPosition + 1);

          if (type === 'lesson-set') {
            $http.get('/lesson-sets/' + id + '/resources').then((response) => {
              // Lesson set can have different subjects .... now this code is useless
              // for (let i = 0; i < response.data.length; i++) {
              //   let itemToAdd = null;
              //   let addedItem = null;
              //   let itemGradeToAdd = null;
              //   let addedGradeItem = null;
              //
              //   if ($scope.lessonSequence.resources[0]['resource_subjects'] !== undefined && $scope.lessonSequence.resources[0]['resource_subjects'][0] !== undefined) {
              //     itemToAdd = $scope.lessonSequence.resources[0]['resource_subjects'][0];
              //     addedItem = response.data[i]['resource_subjects'][0];
              //   }
              //
              //   if ($scope.lessonSequence.resources[0]['resource_grade_levels'] !== undefined && $scope.lessonSequence.resources[0]['resource_grade_levels'][0] !== undefined) {
              //     itemGradeToAdd = $scope.lessonSequence.resources[0]['resource_grade_levels'][0]['id'];
              //     addedGradeItem = response.data[i]['resource_grade_levels'][0]['id'];
              //   }
              //
              //   if (addedItem !== null && itemToAdd !== addedItem) {
              //     for (let i = 0; i < $scope.lessonSequence.resources.length; i++) {
              //       if ($scope.lessonSequence.resources[i]['resource_subjects'] === undefined) {
              //         $scope.lessonSequence.resources.splice(i, 1);
              //       }
              //     }
              //
              //     toastr.error('This item has wrong subject.');
              //     addLessonSetToList(id);
              //     clearItems();
              //     $scope.loading = false;
              //     return;
              //   }
              //
              //   if (addedGradeItem !== null && itemGradeToAdd !== addedGradeItem) {
              //     for (let i = 0; i < $scope.lessonSequence.resources.length; i++) {
              //       if ($scope.lessonSequence.resources[i]['resource_grade_levels'] === undefined) {
              //         $scope.lessonSequence.resources.splice(i, 1);
              //       }
              //     }
              //
              //     toastr.error('This item has wrong grade.');
              //     addLessonSetToList(id);
              //     clearItems();
              //     $scope.loading = false;
              //     return;
              //   }
              // }

              // now let's add parts of lesson set to the first part
              for (let i = 0; i < response.data.length; i++) {
                firstPart.push(response.data[i]);
              }

              // now add rest of the array
              for (let i = 0; i < secondPart.length; i++) {
                firstPart.push(secondPart[i]);
              }

              // finally, swap arrays
              $scope.lessonSequence.resources = firstPart;
              $scope.loading = false;
            }, (errorResponse) => {
              alertProvider.responseError(errorResponse);
              $scope.loading = false;
            });
          } else {
            let content = ui.item.data('content');
            let thumbnail = ui.item.data('thumbnail');
            let title = ui.item.data('title');

            $scope.createYoutubeResource(title, content, thumbnail).then((response) => {
              firstPart.push(response.data);
              // now add rest of the array
              for (let i = 0; i < secondPart.length; i++) {
                firstPart.push(secondPart[i]);
              }

              // finally, swap arrays
              $scope.lessonSequence.resources = firstPart;
              $scope.loading = false;
            }, (errorResponse) => {
              alertProvider.responseError(errorResponse);
            });
          }
        }

        if (type === 'assessment' || type === 'learnetic') {
          $scope.loading = false;
        }

        clearItems();
        colourLessonSets();
      }
    });

    $scope.removeItem = (index, lessonSetId) => {
      // if last element from lesson set was deleted
      if ($scope.lessonSequence.resources[index]['resource_type']['title'] === 'mAuthor') {
        removeLessonSet(lessonSetId);
        addLessonSetToList(lessonSetId);
      } else if ($scope.lessonSequence.resources[index]['resource_type']['title'] === 'PDF') {
        addPdfToList($scope.lessonSequence.resources[index]);
        $scope.lessonSequence.resources.splice(index, 1);
      } else {
        $scope.lessonSequence.resources.splice(index, 1);
      }
    };

    $scope.getRange = (range) => {
      return new Array(range);
    };

    $scope.save = (userId) => {
      if ($scope.lessonSequence.owner_id != userId) {
        toastr.error('This is not yours lesson set. You cannot save.');
        return;
      }

      const { id, title, sequence_category_id: sequenceCategoryId } = $scope.lessonSequence;
      const resources = $scope.lessonSequence.resources.map(({ id }) => id);

      $http.put(`/instructional-sequences/${id}`, {
          sequence_category_id: sequenceCategoryId,
          resources,
          title
        })
        .then(() => {
          alertProvider.success('Sequences updated');
          $window.location.href = '/instructional-sequences';
        })
        .catch(errorResponse => {
          alertProvider.responseError(errorResponse);
        });
    };

    $scope.createResource = (title, content, type, thumbnail = null) => {
      return $http.post('/resources', {
        title, content, type, thumbnail
      });
    };

    $scope.createTextResource = (content) => {
      return $scope.createResource('', content, 'Text');
    };

    $scope.createYoutubeResource = (name, content, thumbnail) => {
      return $scope.createResource(name, content, 'YouTube', thumbnail);
    };

    $scope.updateResource = (resource) => {
      let id = resource.id;
      let title = resource.title;
      let content = resource.content;

      $http.put('/resources/' + id, {
        title, content
      }).then(() => {
        alertProvider.success('Resource updated');
        resource.editMode = false;
      });
    };

    $scope.updateTextResource = (resource) => {
      return $scope.updateResource(resource);
    };

    $scope.changeView = (view) => {
      $scope.view = view;
    };

    $scope.editText = (resource) => {
      let modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/edit-text-resource.html',
        controller: 'TextResourceEditController',
        size: 'md',
        resolve: {
          resource: resource || {}
        }
      });

      modalInstance.result.then((content) => {
        if (resource) {
          resource.content = content;
          $scope.updateTextResource(resource).then(() => {
            resource.content = content;
          });
        } else {
          $scope.createTextResource(content).then((result) => {
            $scope.lessonSequence.resources.push(result.data);
          });
        }
      }).catch(() => {
      });
    };

    $scope.youtubeSearch = (searchString, nextPageToken = '') => {
      if (!searchString) {
        return false;
      }

      if (!nextPageToken) {
        $scope.youtubeResults = [];
      }

      $scope.youtubeSearchString = searchString;
      youtubeProvider.search(searchString, nextPageToken).then((response) => {
        for (let i of response.data.items) {
          $scope.youtubeResults.push(i);
        }
        $scope.nextPageToken = response.data.nextPageToken;
      });
    };

    $scope.youtubeNextPage = () => {
      return $scope.youtubeSearch($scope.youtubeSearchString, $scope.nextPageToken);
    };

    $scope.updateFilters = () => {
      getLessonSets();
    };

    $scope.updatePdfFilters = () => {
      getFilteredPdf();
    };

    $scope.updateCapstonePdfFilters = () => {
      getFilteredCapstonePdf();
    };

    $scope.updateLearneticFilters = () => {
      getFilteredLearnetic();
    };

    $scope.updateAssessmentsFilters = () => {
      getFilteredAssessments();
    };

    $scope.preview = () => {
      $scope.activePreviewItem = $scope.lessonSequence.hasOwnProperty('resources') && $scope.lessonSequence.resources.length
        ? $scope.lessonSequence.resources[0]
        : null;

      if ($scope.activePreviewItem) {
        checkForEmbed();
        $scope.previewVisible = true;
      }
    };

    let checkYoutubeEmbed = () => {
      if ($scope.activePreviewItem.resource_type.title === 'YouTube') {
        $scope.activePreviewItem.iframeUrl = $scope.activePreviewItem.content.replace('watch?v=', 'embed/');
      }
    };

    let checkPdfEmbed = () => {
      if ($scope.activePreviewItem.resource_type.title === 'PDF') {
        $scope.activePreviewItem.iframeUrl = '/build/pdf/web/viewer.html' + Config.assetVersion + '&file=' + encodeURIComponent($scope.activePreviewItem.content);
      }
    };

    let checkForEmbed = () => {
      checkYoutubeEmbed();
      checkPdfEmbed();
    };

    $scope.init = (lessonId) => {
      getData(lessonId);

      SequenceCategoriesApi.getList()
        .then(({ data }) => {
          $scope.sequenceCategories = data;
        })
        .catch(lodash.noop);
    };

    $scope.loadPreview = (item) => {
      $scope.activePreviewItem = item;
      checkForEmbed();
    };

    $scope.nextPreviewItem = () => {
      let index = $scope.lessonSequence.resources.findIndex(elem => elem.id === $scope.activePreviewItem.id);
      let nextIndex = index + 1;
      if (nextIndex > $scope.lessonSequence.resources.length - 1) {
        return false;
      }

      $scope.loadPreview($scope.lessonSequence.resources[nextIndex]);
    };

    $scope.findAllSets = (resource) => {
      let countElementInLessonSet = 0;
      let editMode = resource.editMode;
      let firstElementId = 0;

      if (editMode) {
        clearItems();
        return;
      }

      let id = resource['lesson_set_id'];

      if (id !== null) {
        for (let i = 0; i < $scope.lessonSequence.resources.length; i++) {
          if ($scope.lessonSequence.resources[i]['lesson_set_id'] !== null && id === $scope.lessonSequence.resources[i]['lesson_set_id']) {
            $scope.lessonSequence.resources[i]['selected'] = true;
            if (countElementInLessonSet === 0) {
              firstElementId = i;
            }
            countElementInLessonSet++;
          } else {
            $scope.lessonSequence.resources[i]['firstElement'] = false;
            $scope.lessonSequence.resources[i]['lastElement'] = false;
            $scope.lessonSequence.resources[i]['selected'] = false;
          }
        }

        $scope.lessonSequence.resources[firstElementId]['firstElement'] = true;
        $scope.lessonSequence.resources[firstElementId + countElementInLessonSet - 1]['lastElement'] = true;
      } else {
        clearItems();

        resource.firstElement = true;
        resource.lastElement = true;
        resource.selected = true;
      }
    };

    $scope.prevPreviewItem = () => {
      let index = $scope.lessonSequence.resources.findIndex(elem => elem.id === $scope.activePreviewItem.id);
      let nextIndex = index - 1;
      if (nextIndex < 0) {
        return false;
      }

      $scope.loadPreview($scope.lessonSequence.resources[nextIndex]);
    };

    let addLessonSetToList = (lessonSetId) => {
      if(typeof lessonSetId == 'undefined') { return null; }
      $http.get('/lesson-set/' + lessonSetId).then((response) => {
        if (response.data) {
          $scope.lessonSets.unshift({
            id: lessonSetId, title: response.data['title']
          });
          $scope.lessonSets.sort(function (a, b) {
            return a.title.localeCompare(b.title);
          });
        }
      }, (errorResponse) => {
        alertProvider.responseError(errorResponse);
      });
    };

    let removeLessonSet = (lessonSetId) => {
      let keyToDelete = [];
      angular.forEach($scope.lessonSequence.resources, function (value, key) {
        if (value['lesson_set_id'] === lessonSetId) {
          keyToDelete.push(key);
        }
      });

      $scope.lessonSequence.resources.splice(keyToDelete[0], keyToDelete.length);
      clearItems();
    };

    let addPdfToList = (resource) => {
      $scope.pdfs.push(resource);
      $scope.pdfs.sort(function (a, b) {
        return a.title.localeCompare(b.title);
      });
    };

    $scope.move = (index, type) => {
      let resource = type === 'DOWN'
        ? $scope.lessonSequence.resources[index + 1]
        : $scope.lessonSequence.resources[index - 1];
      let lessonSetId = resource['lesson_set_id'];
      let currentElementCounter = 0;
      let elementCounter = 0;

      angular.forEach($scope.lessonSequence.resources, function (resource) {
        if (resource['lesson_set_id'] === lessonSetId) {
          elementCounter++;
        } else if (resource['lesson_set_id'] === $scope.lessonSequence.resources[index]['lesson_set_id']) {
          currentElementCounter++;
        }
      });

      if ($scope.lessonSequence.resources[index]['resource_type']['title'] !== 'mAuthor') {
        currentElementCounter = 1;
      }

      if (resource['resource_type']['title'] !== 'mAuthor') {
        elementCounter = 1;
      }

      if (type === 'DOWN') {
        let lastIndexOfNextElement = index + elementCounter;

        for (let i = 0; i < currentElementCounter; i++) {
          moveItem(index - i, lastIndexOfNextElement - i);
        }
      } else if (type === 'UP') {
        let firstIndexOfPrevElement = index - elementCounter;
        for (let i = 0; i < currentElementCounter; i++) {
          moveItem(index + i, firstIndexOfPrevElement + i);
        }
      }
    };

    let moveItem = (origin, destination) => {
      let temp = $scope.lessonSequence.resources[destination];
      $scope.lessonSequence.resources[destination] = $scope.lessonSequence.resources[origin];
      $scope.lessonSequence.resources[origin] = temp;
    };

    let clearItems = () => {
      angular.forEach($scope.lessonSequence.resources, function (resource) {
        resource.firstElement = false;
        resource.lastElement = false;
        resource.selected = false;
      });
    };

    let colourLessonSets = () => {
      let colourSetNumber = 0;
      let initialLessonSetId = 1;
      angular.forEach($scope.lessonSequence.resources, function (resource) {
        if (resource['lesson_set_id'] !== initialLessonSetId) {
          colourSetNumber++;
          initialLessonSetId = resource['lesson_set_id'];
        }
        resource.colour = colourSetNumber;
      });
    };

    $scope.openEditPanel = () => {
      $scope.hideEditPanel = !$scope.hideEditPanel;
    };

    let getAssignedResourceFromLessonSequence = () => {
      if(typeof $scope.lessonSequence.id != 'undefined') {
        $http.get('/assignments/lesson-sequence-assigned-resources/' + $scope.lessonSequence.id).then((response) => {
          let assignedResources = response.data;
          if ($scope.lessonSequence.resources) {
            for (let resource of $scope.lessonSequence.resources) {
              if (assignedResources.length) {
                for (let assignedResource of assignedResources) {
                  if (assignedResource.resource_id == resource.id) {
                    resource.assigned = true;
                    if (assignedResource.submitted) {
                      resource.submitted = true;
                      if (assignedResource.scored) {
                        resource.scored = true;
                      }
                    }
                  }
                }
              } else {
                resource.assigned = false;
              }
            }
          }
        }, (data) => {
          console.error(data);
        });
      }
    };

    $scope.lsPopUpResource = (e, resource) => {
      e.preventDefault();
  
      $uibModal.open({
        animation: true,
        templateUrl: '/views/player.html',
        controller: 'PlayerController',
        windowClass: 'player-modal-window',
        resolve: { resource }
      });
    };

  }]);
