spApp.directive('stateDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/state-filter.html',
    scope: {
      selectedStates: '='
    },
    link: function ($scope) {
      $http.get('/states/list').then(function (response) {
        $scope.states = response.data;
      }, function (data) {
        console.error(data);
      });
      $scope.stateChanged = function () {
        $scope.selectedStates = $scope.states.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
