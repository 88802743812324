spApp.controller('SequenceCategoryAddController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', 'category',
  function ($scope, $http, $uibModalInstance, alertProvider, category) {
    const validateColor = () => {
      return /^#[a-z0-9]{6}$/i.test($scope.category.color)
    };

    $scope.category = category || {
      title: ''
    };

    $scope.validColor = validateColor();

    $scope.$watch('category.color', () => {
      $scope.validColor = validateColor();
    });

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      const url = $scope.category.id
        ? `/sequence-categories/${$scope.category.id}`
        : '/sequence-categories';

      const method = $scope.category.id
        ? 'put'
        : 'post';

      $http[method](url, $scope.category)
        .then(response => {
          alertProvider.success('Category saved.');
          $uibModalInstance.close();
        })
        .catch(response => {
          alertProvider.responseError(response);
        })
    };
  }
]);
