spApp.controller('LessonSequencePreviewController', [
  '$scope', 'lodash', '$uibModal', '$http', 'Resources.assignModal', 'alertProvider',
  function ($scope, lodash, $uibModal, $http, assignModal, alertProvider) {
    $scope.resources = [];
    $scope.lessonSequence = null;

    $scope.init = (lessonSequence) => {
      $scope.lessonSequence = lessonSequence;

      if(!$scope.lessonSequence.resources) {
        return;
      }

      $scope.resources = lessonSequence.resources.map(item => ({
        ...item,
        fullWidth: (item.resource_type.title != 'mAuthor'),
        resource_standards: {
          ...item.resource_standards,
          displayText: lodash.join(
            lodash.map(item.resource_standards, 'title'),
            ', '
          )
        },
        type: (item.resource_type.title === 'mAuthor')
          ? 'lesson'
          : item.resource_type.title.toLowerCase()
      }));

    };

    $scope.lsPopUpResource = (e, resource) => {
        e.preventDefault();
    
        $uibModal.open({
          animation: true,
          templateUrl: '/views/player.html',
          controller: 'PlayerController',
          windowClass: 'player-modal-window',
          resolve: { resource }
        });
    };

    $scope.showInPlayer = (resource) => {
      $uibModal.open({
        animation: true,
        templateUrl: '/views/player.html',
        controller: 'PlayerController',
        windowClass: 'player-modal-window',
        resolve: { resource }
      });
    };

    $scope.addAssignmentModal = (resource) => {
      assignModal(resource, 'resource', { lessonSequenceId: $scope.lessonSequence.id })
        .then(lodash.noop, lodash.noop);
    };

    $scope.assignAll = () => {
      assignModal($scope.lessonSequence, 'lesson-sequence')
        .then(lodash.noop, lodash.noop);
    };

    $scope.isAssessment = resource => resource.resource_lesson_types.some(item => item.title === 'Assessment')

    $scope.isSingleLesson = resource => !resource.lesson_set_id
      ? false
      : $scope.resources.filter(
          ({ lesson_set_id }) => lesson_set_id === resource.lesson_set_id
        ).length === 1

    $scope.checkOwner = (userId) => userId == $scope.lessonSequence.owner_id;

    $scope.deleteLessonSequence = () => {
      $http.delete(`/instructional-sequences/${$scope.lessonSequence.id}`)
        .then(() => {
          window.location = '/instructional-sequences/';
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };
  }
]);
