spApp.controller('StudentController', [
  '$scope', '$http', '$uibModal', 'alertProvider', 'toastr', '$window',
  function($scope, $http, $uibModal, alertProvider, toastr, $window) {
    $scope.data = {};
    $scope.grades = [];
    $scope.loading = false;
    $scope.selected = {
      school: null,
      teachers: [],
      grade: null,
      teacher: null
    };

    $scope.filters = {
      number: '',
      name: '',
      sort: []
    };
    $scope.active = true;
    $scope.user = true;

    $scope.clever = {
      loading: false
    };

    const getInitialPaging = () => ({
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0,
    });

    $scope.paging = getInitialPaging();
    $scope.licenseYear = (license) => {
      const regex = /^\d{2}(\d{2})/;
      const [, startYear] = regex.exec(license.date_start);
      const [, endYear] = regex.exec(license.date_end);

      return `${startYear}/${endYear}`;
    };

    $scope.$watchGroup(
      [
        'selected.school',
        'selected.teachers',
        'selected.grade',
      ],
      () => {
        $scope.getData();
      }
    );

    $scope.licenceColumns = []

    const computeLicenceColumns = () => {
      $scope.licenceColumns = $scope.licences.map(item => ({
        title: item.flex_code_title,
        _ids: item._ids,
        number: item.number,
        number_available: item.number_available,
        subject: item.subject,
        grade_id: item.grade_id,
        date_start: item.date_start,
        date_end: item.date_end,
      }));
    };

    const mapStudentsLicences = () => {
      $scope.data.data = $scope.data.data.map(item => {
        const activeLicences = item.active_licences;

        return {
          ...item,
          active_licences: $scope.licenceColumns.map(licence => ({
            active: activeLicences.some(({ id }) => licence._ids.includes(id)),
            _ids: licence._ids,
            subject: licence.subject,
            grade_id: licence.grade_id
          }))
        };
      })
    };

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    //Sorting students table
    $scope.$on('sorting', (event, data) => {
      $scope.filters.sort = [data];
      $scope.getData();
      $scope.$broadcast('selectedSort', $scope.filters.sort)
    })

    $scope.getData = (page) => {

      // do not try to select anything
      if (!$scope.selected.school) {
        $scope.data = [];
        $scope.licences = [];
        $scope.paging = getInitialPaging();
        $scope.licenceColumns = [];

        return;
      }

      $scope.loading = true;

      const params = {
        number: $scope.filters.number,
        name: $scope.filters.name,
        perPage: $scope.paging.perPage,
        active: $scope.active,
        'schools[]': [$scope.selected.school.id],
        'teachers[]': $scope.selected.teachers,
        grade: $scope.selected.grade,
        'sort[]': $scope.filters.sort,
        ...(page ? { page } : {})
      };

      const promises = [
        $http.get('/students/filter', { params }),
        $http.get('/licences/filter', { params })
      ];

      Promise.all(promises)
        .then(data => {
          const [students, licences] = data;

          $scope.data = {
            ...students.data,
            data: students.data.data.map(item => ({
              ...item,
              from_clever: students.data.connected_with_clever.includes(item.id),
            }))
          };

          $scope.setPagingData(students.data);

          $scope.licences = licences.data;
          $scope.loading = false;

          computeLicenceColumns();
          mapStudentsLicences();

          $scope.$apply();
        })
        .catch(console.error)
    };

    $scope.editStudentModal = (role, student = {}) => {
      let modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/edit-student.html',
        controller: 'StudentEditController',
        size: 'lg',
        resolve: {
          student: student,
          user: {role},
        },
      });

      modalInstance.result.then(() => {
        $scope.getData($scope.paging.currentPage);
      }, () => {

      });
    };

    $scope.addLicence = (student, licences) => {
      $http.post(`/students/${student.id}/licences`, { licences })
        .then((response) => {
          $scope.getData($scope.paging.currentPage);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.addLicenceForAll = (licence) => {
      const params = {
        schoolId: $scope.selected.school.id,
        gradeId: licence.grade_id || $scope.selected.grade,
        licences: licence._ids
      };

      $http.post(`/students/licences/all`, params)
        .then(response => {
          $scope.getData($scope.paging.currentPage);

          if(response.data == 0) {
            toastr.error(`Number of licences added: ${response.data}`);
          } else {
            toastr.success(`Number of licences added: ${response.data}`);
          }
        })
        .catch(response => {
          alertProvider.responseError(response);
        });
    };

    $scope.removeLicence = (student, licences) => {
      $http.post(`/students/${student.id}/licences`, { licences, _method: 'DELETE' })
        .then(response => {
          $scope.getData($scope.paging.currentPage);
        })
        .catch(response => {
          alertProvider.responseError(response);
        });
    };

    $scope.setPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.init = ({ user, schools, grades, forceSchool }) => {
      $scope.schools = schools;
      $scope.grades = grades;
      $scope.selected.school = !!forceSchool
        ? forceSchool
        : null;

      $scope.selected.grade = (grades.length === 1)
        ? grades[0].id
        : null;

      $scope.user = user;
    };

    $scope.syncWithClever = () => {
      $scope.clever.loading = true;

      $http.post('/_clever/sync/students')
        .then(response => {
          $window.location.reload();
        })
        .catch(error => {
          $scope.clever.loading = false;
          alertProvider.responseError(error);
        });
    };

    $scope.getDeleteAlertText = () => {
      const gradeTitle = $scope.grades.find(({ id }) => id == $scope.selected.grade).title;
      const schoolName = $scope.selected.school.name;
      const districtName = $scope.selected.district.name;

      return `Are you sure you want to delete the students in ${districtName} district, ${schoolName} school, ${gradeTitle} grade? This will delete all selected user data and cannot be recovered.`;
    }

    $scope.destroyStudents = () => {
      $scope.loading = true;

      const res = confirm($scope.getDeleteAlertText())

      if (res) {

        const params = {
          grade_id: $scope.selected.grade,
          school_id: $scope.selected.school.id
        };

        $http.delete('/students', { params })
          .then(() => {
            toastr.success('Removed all students for selected grade');
            $scope.getData();
          })
          .catch(error => {
            $scope.loading = false;
            alertProvider.responseError(error);
          });
      }
    }

    $scope.$on('selectedGradeSchool', function (event, obj) {
      $scope.selected.district = obj.district;
      $scope.selected.school = obj.school;
      $scope.selected.grade = obj.grade;
      $scope.selected.teachers = obj.teacher;

      $scope.getData();
    });
  }
]);
