spApp.directive('groupDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/group-filter.html',
    scope: {
      selectedGroups: '=',
      groupParams: '='
    },
    link: function ($scope) {
      let query = '';

      if ( $scope.groupParams && $scope.groupParams.type) {
        query = '?type=' + $scope.groupParams.type;
      }
      
      $http.get('/groups/list' + query).then(function (response) {
        $scope.groups = response.data;
      }, function (data) {
        console.error(data);
      });

      $scope.groupChanged = function () {
        $scope.selectedGroups = $scope.groups.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
