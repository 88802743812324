spApp.controller('LessonSequencesAddController', [
  '$scope', '$http', '$uibModalStack', '$window', 'authUser', 'lodash', 'SequenceCategories.api', 'alertProvider',
  function($scope, $http, $uibModalStack, $window, authUser, lodash, SequenceCategoriesApi, alertProvider) {
    $scope.newLessonSequence = {};
    $scope.authUser = authUser;
    $scope.categories = [];

    $scope.save = () => {
      const { title, sequence_category: sequenceCategory } = $scope.newLessonSequence;
      const data = {
        title,
        sequence_category_id: sequenceCategory
          ? sequenceCategory.id
          : null
      };

      $http.post('/instructional-sequences', data)
        .then((response) => {
          $window.location.href = `/instructional-sequences/${response.data.id}/builder`;
        })
        .catch(error => {
          alertProvider.responseError(error)
        });
    };

    if (authUser.role === 'IT Administrator') {
      SequenceCategoriesApi.getList()
        .then(({ data }) => {
          $scope.categories = data;
        })
        .catch(lodash.noop);
    }

    $scope.cancel = () => {
      $uibModalStack.dismissAll();
    };
  }]);
