spApp.controller('ShareAppController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', '$window',
  function ($scope, $http, $uibModalInstance, alertProvider, $window) {
      $scope.model = {
        firstname: '',
        email: ''
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss();
      };

      $scope.share = () => {
        $http.post('/shares/app', $scope.model)
          .then((response) => {
            alertProvider.success(response.data.message);
            $uibModalInstance.close();
          })
          .catch((response) => {
            alertProvider.responseError(response);
          });
      };
  }
]);
