spApp.directive('licenseUsageDashboardDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/directives/license-usage-dashboard-directive.html',
    scope: {
      type: '@',
      params: '=',
      user: '@'
    },
    link: function ($scope) {
      $scope.showPie = false;
      $scope.showTable = false;
      $scope.licenseOptions = {
        chart: {
          type: 'pieChart',
          height: 400,
          x: function (d) {
            return d.key;
          },
          y: function (d) {
            return d.y;
          },
          showLabels: false,
          transitionDuration: 500,
          //legendPosition: 'right',
          valueFormat: function (d) {
            return (d / $scope.licenses.total * 100).toFixed(2) + "%  (" + d + " licences)";
          },
          pie: {
            dispatch: {
              elementClick: function (e) {
                if(e.data.key=="Unactivated") {
                  $scope.$apply(function(){
                    if ($scope.user === 'district') {
                      $scope.toggleShowPie();
                    }
                  });
                }
              }
            }
          }
        }
      };

      $scope.options = {
        chart: {
          type: 'pieChart',
          height: 400,
          x: function (d) {
            return d.key;
          },
          y: function (d) {
            return d.y;
          },
          showLabels: false,
          transitionDuration: 500,
          showLegend: false,
          valueFormat: function (d) {
            return  d + " licences";
          }
        }
      };


      $scope.$watch("[params]", function(newValue) {
        if (newValue)
          $scope.getLicensesData($scope.params);
          $scope.getUnactivatedLicensesData($scope.params);
      }, true);

      $scope.getLicensesData = (params) =>{
        $scope.type ? params.type = $scope.type.id : 1;
        $http.get('/data-old/licenses', {params: params}).then((response) => {
          $scope.licenseData = [
            {
              key: "Unactivated",
              y: response.data.sumAvailableLicences,
              color: "#cc0000"
            },
            {
              key: "Activated",
              y: response.data.sumUnavailableLicences,
              color: "#008000"
            }
          ];
          $scope.licenses = response.data;
        }, (data) => {
          //
        });
      };

      $scope.getUnactivatedLicensesData = (params) =>{
        $scope.type ? params.type = $scope.type : "Math";
        $http.get('/data-old/un-activated-licenses', { params }).then((response) => {
          $scope.data = response.data;
          $scope.showPie = false;
        }, (data) => {
        });
      };

      $scope.toggleShowPie = () => {
        $scope.showPie = !$scope.showPie;
      };

      $scope.toggleShowTable = () => {
        $scope.showTable = !$scope.showTable;
      }
    }
  }
}]);
