spApp.directive('resourceStandardDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-standard-filter.html',
    scope: {
      selectedStandards: '='
    },
    link: function ($scope) {
      const setSelectedStandard = $scope => () => {
        const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));

        if (!urlParams.selectedStandards) {
          return;
        }

        const selectedStandards = urlParams.selectedStandards.map(id => parseInt(id, 10))
          .filter(id => !isNaN(id));

        $scope.selectedStandards = selectedStandards || [];
        $scope.standards = $scope.standards.map((item) => ({
          ...item,
          selected: selectedStandards.includes(item.id),
        }));
      };

      $http.get('/resources/standards')
        .then((response) => {
          $scope.standards = response.data;
        })
        .then(setSelectedStandard($scope));

      $scope.standardsChanged = function () {
        $scope.selectedStandards = $scope.standards.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
