spApp.directive('alertDirective', [
  'alertProvider',
  function (alertProvider) {
    return {
      restrict: 'E',
      scope: {
        type: '@',
        message: '@'
      },
      link: function ($scope) {
        setTimeout(() => {
          alertProvider[$scope.type]($scope.message);
        }, 500);
      }
    }
  }
]);
