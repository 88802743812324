spApp.directive('assignmentScoredDirective', [function () {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/assignment-scored-filter.html',
    scope: {
      selectedScored: '='
    },
    link: function ($scope) {
      $scope.scored = [
        {id:1, title: "Scored"},
        {id:2, title: "Unscored"}
      ];
      $scope.statusChanged = function () {
        $scope.selectedScored = $scope.scored.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
