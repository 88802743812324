spApp.controller('ShareSchoolAdminController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', 'user',
  function($scope, $http, $uibModalInstance, alertProvider, user) {

    let initSharedData = () => {
        for (let teacher of $scope.teachers) {
          if ($scope.sharedIdTeachers.indexOf(teacher.id) >= 0) {
            teacher.selected = true;
          }
          else {
            teacher.selected = false;
          }
        }
      for (let grade of $scope.grades) {
        if ($scope.sharedIdGrades.indexOf(grade.id) >= 0) {
          grade.selected = true;
        }
        else {
          grade.selected = false;
        }
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      let selectedTeachers = $scope.teachers.filter(item => item.selected).
        map(item => item.id);

      let selectedGrades = $scope.grades.filter(item => item.selected).
        map(item => item.id);


      $http.post('/shares-school-admin', {
        item: $scope.item,
        teachers: selectedTeachers,
        grades: selectedGrades
      }).then((response) => {
        $uibModalInstance.close(response.data);
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    };

    $scope.getData = () => {

      $http.get('/grades/list').then((response) => {
        $scope.grades = response.data;
      }, (data) => {
        console.error(data);
      });

        $http.get('/users/list').then((response) => {
          $scope.teachers = response.data;
        }, (data) => {
          console.error(data);
        });

      $http.get('/instructional-sequences/to-share-admin').then((response) => {
        $scope.items = response.data;
      }, (data) => {
        console.error(data);
      });
    };

    $scope.getSharedData = () => {

      $http.get('/shares-admin/' + $scope.item.id).then((response) => {
        let shares = response.data;
        $scope.sharedIdGrades = shares.filter(item => item.share_to_type == 'grade').map(item => item.share_to_id);
        $scope.sharedIdTeachers = shares.filter(item => item.share_to_type == 'teacher').map(item => item.share_to_id);
        initSharedData();
      }, (data) => {
        console.error(data);
      });
    };

    $scope.selectedItem = function() {
      $scope.getSharedData();
    };

    $scope.init = () => {
      $scope.user = user;
      $scope.getData();

    };

    $scope.init();
  }]);
