spApp.controller('AssignmentStudentScoreController', [
  '$scope',
  'toastr',
  '$window',
  '$http',
  'alertProvider',
  'striptags',
  function ($scope, toastr, $window, $http, alertProvider, striptags) {
    $scope.assignmentsStudents = [];
    $scope.assignmentsList = [];
    $scope.rubrics = [];
    $scope.autoScoredRubrics = [];
    $scope.assignmentId = 0;
    $scope.currentResourceId = null;
    $scope.assignmentStudentId = null;
    $scope.currentStudent = null;
    $scope.currentStudentObject = null;
    $scope.scoresByResource = null;
    $scope.currentAssignmentStudentResource = null;
    $scope.nextStudentToScore = null;
    $scope.scoreAdjust = 0;
    $scope.summary = {
      closeQuestion : 0,
      closeQuestionUpdated: false,
      openQuestion: 0,
      score: 0,
      maxPoints: 0
    };
    $scope.pointsEarned = 0;
    $scope.assignment = null;
    $scope.scrolledThroughPages = false;

    let playerInstance;

    $scope.init = (assignmentId, assignmentStudentId) => {
      Promise.all([getAssignment(assignmentId), getResources(assignmentStudentId), getData(assignmentId)])
        .then(() => {
          updateCurrentStudentObject();
        });

      $scope.assignmentStudentId = assignmentStudentId;
      $scope.assignmentId = assignmentId;
    };

    $scope.selectResource = {};

    const updateCurrentStudentObject = () => {
      $scope.currentStudentObject = $scope.assignmentsList.map(({ student }) => student)
        .find(({ id }) => id === $scope.currentStudent);
    }

    const contentToPageNumber = (content) => {
      const clearedString = striptags(content)
        .replace(new RegExp('&nbps;', 'g'), ' ')
        .trim();
      const number = parseInt(clearedString, 10);

      return isNaN(number)
        ? clearedString
        : number;
    };

    const pullScoringFromPlayer = (instance) => {
      if (!instance) {
        return;
      }

      const isScored = ({score, checkCount, errorCount}) => (score || checkCount || errorCount);
      const ps = instance.getPlayerServices();
      const model = ps.getPresentation();
      const scoreService = ps.getScore();
      const scoreMap = Array.from(Array(model.getPageCount()), (_, i) => {
        const page = model.getPage(i);
        const content = i + 1;

        if(!page.isReportable()){
          return {content, score: 0, isScored: false};
        }

        const item = scoreService.getPageScoreById(page.getId());
        return {content, score: item.score, isScored: isScored(item)};
      });

      scoreMap.forEach(({content, score, isScored}) => {
        const rubric = $scope.autoScoredRubrics.find(item => item.content === content);

        if (rubric && isScored) {
          rubric.checked = score > 0;
        };
      });

      const points = scoreMap.filter(({isScored, score}) => isScored && score > 0).length;

      if (points != $scope.summary.closeQuestion) {
        $scope.summary.closeQuestion = points;
        $scope.summary.closeQuestionUpdated = true;
      }

      $scope.$apply();
    };

    /**
     * ICPlayer has a problem.
     *
     * For some reason it does not always pull scoring details of all pages.
     * Sometimes it's required to loop through all of them to receive
     * an acurate scoring.
     *
     * This function will jump from page to page. At the end it will jump
     * back to the first page.
     *
     * It's based on events - function invokes gotoPageIndex(). This forces player
     * to reload the page. `player:pageLoaded` event is triggered and then this
     * function is invoked once again.
     */
    const scrollThroughPages = (instance) => {
      const ps = instance.getPlayerServices();
      const presentation = ps.getPresentation();
      const commands = ps.getCommands();
      const currentPage = ps.getCurrentPageIndex();
      const totalPages = presentation.getPageCount();

      if (totalPages === 1) {
        $scope.scrolledThroughPages = true;
        $scope.$apply();
        return;
      }

      if (currentPage + 1 === totalPages) {
        $scope.scrolledThroughPages = true;
        commands.gotoPageIndex(0);
        return;
      }

      commands.gotoPageIndex(currentPage + 1);
    }

    $scope.$on('player:pageLoaded', (event, player) => {
      event.stopPropagation();

      if (!player) {
        return;
      }

      playerInstance = player.playerInstance;

      const state = $scope.currentAssignmentStudentResource.player_state;

      if (state) {
        playerInstance.setState(state);
        // $scope.scrolledThroughPages = true;
      }

      if (!$scope.scrolledThroughPages) {
        scrollThroughPages(playerInstance);
      } else {
        pullScoringFromPlayer(playerInstance);
      }

      $scope.$apply();
    });

    const getResources = (assignmentId, { checkScores = false } = {}) => {
      return $http.get(`/assignment/${assignmentId}/resources`)
        .then(
          (response) => {
            $scope.assignmentsStudents = response.data;            

            if (!$scope.assignmentsStudents.length) {
              return;
            }
            $scope.scoreAdjust = parseFloat($scope.assignmentsStudents[0].mauthor_score_adjust) ? parseFloat($scope.assignmentsStudents[0].mauthor_score_adjust) : 0;

            if ($scope.currentResourceId === null) {
              $scope.currentAssignmentStudentResource = $scope.assignmentsStudents[0];
              $scope.currentResourceId = $scope.assignmentsStudents[0].resource.id;
              $scope.currentStudent = $scope.assignmentsStudents[0]['assignment_student']['student_id'];

              updateCurrentStudentObject();
            }

            $scope.getRubric($scope.currentResourceId, { checkScores });
          },
          (data) => {
            alertProvider.responseError(data);
          }
        );
    };

    const getAssignment = (assignmentId) => $http.get(`/assignments/${assignmentId}`)
      .then(({data}) => {
        $scope.assignment = data;
      })
      .catch(err => {
        alertProvide.responseError(err);
      });

    const getData = (assignmentId) => {
      return $http.get(`/assignments/${assignmentId}/students/list`)
        .then((response) => {
          $scope.assignmentsList = response.data;
          updateCurrentStudentObject()

          setNextStudentToScore();
        },
        (data) => {
          alertProvider.responseError(data);
        }
      );
    };

    $scope.getRubric = (resourceId, { checkScores = false } = {}) => {
      $http.get(`/assignments/rubrics/${resourceId}/rubric-criteria`)
        .then(response => response.data.map(item => ({
          ...item,
          content: contentToPageNumber(item.content)
        })))
        .then((data) => {
          $scope.rubrics = data.filter(({auto_scored}) => !auto_scored);
          $scope.autoScoredRubrics = data.filter(({auto_scored}) => auto_scored);
          $scope.getScores(resourceId, $scope.assignmentStudentId, { checkScores });
          $scope.refreshPoints();
          pullScoringFromPlayer(playerInstance);
        })
        .catch(error => {
          alertProvider.responseError(error);
        });
    };

      $scope.getScores = (resourceId, assignmentStudentId, { checkScores }) => {
          $http.get(`/assignments/resources/student/${assignmentStudentId}/resource/${resourceId}`)
            .then(
              (response) => {
                $scope.summary.openQuestion = 0;
                $scope.scoresByResource = response.data;

                angular.forEach($scope.rubrics, function (rubric) {
                    angular.forEach($scope.scoresByResource, function (score) {
                        if(rubric.id == score['rubrics_criteria_id'] && score['correct_answer'] == 1) {
                            rubric.checked = true;
                            $scope.summary.openQuestion ++;
                        } else if (rubric.id == score['rubrics_criteria_id'] && score['correct_answer'] == 0) {
                            rubric.checked = false;
                        }
                    })
                });
                
                $scope.scoreAdjustAutomaticChange();

                if (checkScores) {
                  const checkedRubrics = $scope.rubrics.filter(rubric => rubric.hasOwnProperty('checked'));
                  const counter = checkedRubrics.length;

                  if ($scope.rubrics.length === counter) {
                      $scope.save({redirect: false});
                  }
                }

            },
            (response) => {
                $scope.formError = response.data.message;
            }
          );
      };

    $scope.markAnswer = (rubricCriteriaId, answerType) => {
        $http.post('/assignments/rubrics/markAnswer', {
            rubricsCriteriaId: rubricCriteriaId,
            answerType: answerType,
            assignmentStudentId: $scope.assignmentStudentId
        }).then(() => {
            getResources($scope.assignmentStudentId, { checkScores: true });
        }, (response) => {
            $scope.formError = response.data.message;
        });
    };

    $scope.refreshPoints = () => {
      const assignment = $scope.assignmentsStudents.find(
        ({ resource_id }) => resource_id === $scope.currentResourceId
      );

      if (assignment) {
        $scope.summary.closeQuestion = parseInt(assignment.mauthor_score, 10);
        $scope.summary.openQuestion = parseInt(assignment.score, 10);
        $scope.summary.maxPoints = parseInt(assignment.max_score, 10);
        $scope.pointsEarned = $scope.scoreAdjust + $scope.summary.closeQuestion + $scope.summary.openQuestion;
        $scope.pointsEarned = Math.round($scope.pointsEarned * 100) / 100;
      }
    };

    $scope.scoreAdjustAutomaticChange = () => {      
      $scope.pointsEarned = $scope.scoreAdjust + $scope.summary.closeQuestion + $scope.summary.openQuestion;

      if(document.getElementsByName("score-adjust")[0]) {
        document.getElementsByName("score-adjust")[0].classList.remove('error');
      }
      if($scope.pointsEarned > $scope.summary.maxPoints) {
        let diff = $scope.pointsEarned - $scope.summary.maxPoints;
        $scope.scoreAdjust -= diff;
        $scope.scoreAdjust = Math.round($scope.scoreAdjust * 100) / 100;
      }
      if($scope.pointsEarned < 0) {
        let diff = 0 - $scope.pointsEarned;
        $scope.scoreAdjust += diff;
        $scope.scoreAdjust = Math.round($scope.scoreAdjust * 100) / 100;
      }

      $scope.pointsEarned = $scope.scoreAdjust + $scope.summary.closeQuestion + $scope.summary.openQuestion;
      $scope.pointsEarned = Math.round($scope.pointsEarned * 100) / 100;
    };

    $scope.scoreAdjustChange = () => {
      $scope.scoreAdjust = 0;
      if(document.getElementsByName("score-adjust")[0]) {
        $scope.scoreAdjust = parseFloat(document.getElementsByName("score-adjust")[0].value);
      }
      if(!$scope.scoreAdjust) { $scope.scoreAdjust = 0; }
      
      $scope.pointsEarned = $scope.scoreAdjust + $scope.summary.closeQuestion + $scope.summary.openQuestion;

      if(document.getElementsByName("score-adjust")[0]) {
        document.getElementsByName("score-adjust")[0].classList.remove('error');
      }
      if($scope.pointsEarned > $scope.summary.maxPoints) {
        let diff = $scope.pointsEarned - $scope.summary.maxPoints;
        $scope.scoreAdjust -= diff;
        $scope.scoreAdjust = Math.round($scope.scoreAdjust * 100) / 100;
        //document.getElementsByName("score-adjust")[0].value = $scope.scoreAdjust;
        if(document.getElementsByName("score-adjust")[0]) {
          document.getElementsByName("score-adjust")[0].classList.add('error');
        }
      }
      if($scope.pointsEarned < 0) {
        let diff = 0 - $scope.pointsEarned;
        $scope.scoreAdjust += diff;
        $scope.scoreAdjust = Math.round($scope.scoreAdjust * 100) / 100;
        //document.getElementsByName("score-adjust")[0].value = $scope.scoreAdjust;
        if(document.getElementsByName("score-adjust")[0]) {
          document.getElementsByName("score-adjust")[0].classList.add('error');
        }
      }

      $scope.pointsEarned = $scope.scoreAdjust + $scope.summary.closeQuestion + $scope.summary.openQuestion;
      $scope.pointsEarned = Math.round($scope.pointsEarned * 100) / 100;
    };

    $scope.save = ({redirect = true} = {}) => {
      if(!validate()) {
          return;
      }

      $http.put(`/assignments/${$scope.currentAssignmentStudentResource.id}`, {
        score: $scope.summary.openQuestion,
        mauthorScore: $scope.summary.closeQuestion,
        scoreAdjust: $scope.scoreAdjust
      }).then((response) => {
        toastr.success('Saved successfully');

        const assignmentStudent = $scope.assignmentsList.filter((item) => item.id == response.data.id)
          .pop();

        assignmentStudent.score = response.data.score;
        $scope.currentAssignmentStudentResource.scored = true;

        if ($scope.nextStudentToScore && redirect) {
          $window.location.href = `/assignments/${$scope.assignmentId}/assignments-students/${$scope.nextStudentToScore.id}`;
        }
      }, (response) => {
        $scope.formError = response.data.message;
      });
    };

    const validate = () => {
        let isValid = true;

        angular.forEach($scope.rubrics, function (rubric) {
            if(rubric.checked === undefined) {
                toastr.error('Please score rubric with question: ' + rubric.content);
                isValid = false;
                return false;
            }
        });

        return isValid;
    };

    const setNextStudentToScore = () => {
      $scope.assignmentsList.filter(assignment => assignment.submitted == 1)
        .forEach((assignment, index, arr) => {
          if (assignment.id == $scope.assignmentStudentId) {
            $scope.nextStudentToScore = arr[index + 1];
          }
        })
    }
}]);
