const spApp = angular
  .module('spApp', [
    'ui.bootstrap',
    'ui.sortable',
    'toastr',
    'summernote',
    'angucomplete-alt',
    'nvd3',
    'ui.sortable.multiselection',
    'ngMessages',
    'infinite-scroll'
  ])
  .constant('lodash', window._.noConflict())
  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.defaults.headers
      .common['X-Requested-With'] = 'XMLHttpRequest';
  }]);
