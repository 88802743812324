spApp.directive('resourceSequenceCategoryDirective', [
  '$http', 'SequenceCategories.api',
  function ($http, SequenceCategoriesApi) {
    return {
      restrict: 'E',
      templateUrl: '/views/filters/resource-sequence-category-filter.html',
      scope: {
        selectedCategories: '=',
        categories: '='
      },
      link: function ($scope) {
        $scope.categoryChanged = function () {
          $scope.selectedCategories = $scope.categories.filter(item => item.selected)
            .map(item => item.id);
        };
      }
    };
  }
]);
