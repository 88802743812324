spApp.factory('Resources.assignModal', [
  '$uibModal',
  function ($uibModal) {
    return (resource, type = 'resource', options = {}) => {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/add-assignment.html',
        controller: 'AssignmentAddController',
        size: 'md',
        resolve: {
          options: {
            ...options,
            item: resource,
            type
          }
        }
      });

      return modalInstance.result;
    }
  }
])
