spApp.filter('subjectFilter', [() => {
  const hasSubject = subjectId => ({ teachers }) =>
    teachers.some(teacher => teacher.pivot.subject_id === subjectId)

  return  (inputArray, subjectId) => {
    return !subjectId
      ? inputArray
      : inputArray.filter(hasSubject(subjectId))
  }
}]);
