spApp.directive('resourceGradeDirective', ['$http', 'loadable', function ($http, loadable) {
  const setSelectedGrade = $scope => () => {
    const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));

    if (!urlParams.selectedGrade) {
      return;
    }

    const selectedGrade = urlParams.selectedGrade;

    $scope.selectedGrades = [selectedGrade];
    $scope.grades = $scope.grades.map(grade => ({
      ...grade,
      selected: grade.id == urlParams.selectedGrade
    }))
  };
  const loadGrades = ($http) => $http.get('/resources/grades')
    .then(
      response => response.data,
      error => {
        console.error(error);
        return Promise.reject(error);
      }
    );

  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-grade-filter.html',
    scope: {
      selectedGrades: '=',
      gradesList: '=?grades',
    },
    link: function ($scope) {
      const loaded = loadable($scope);

      $scope.hash = window.location.hash.substr(1).toLowerCase();

      const promise = $scope.gradesList
        ? Promise.resolve($scope.gradesList)
        : loadGrades($http);

      promise
        .then(grades => {
          $scope.grades = grades;

          $scope.grades.forEach((el) => {
            if (el.title !='' && $scope.hash === el.title.toLowerCase()) {
              $scope.selectedGrades = [el.id];
              el.selected = true;
            }
          })
          $scope.hash = '';
          window.location.hash = '';
        })
        .then(setSelectedGrade($scope))
        .then(() => {
          loaded();
        });

        $scope.gradeChanged = function () {
          $scope.selectedGrades = $scope.grades.filter(function (item) {
            return item.selected;
          }).map(function (item) {
            return item.id;
          });
        };
    }
  };
}]);
