spApp.directive('player', [
  '$http',
  'PlayerControls',
  'alertProvider',
  '$filter',
  '$window',
  function($http, PlayerControls, alertProvider, $filter, $window) {
    return {
      restrict: 'E',
      templateUrl: '/views/directives/player-directive.html',
      scope: {
        id: '=',
        user: '=',
        selectResource: '=',
        studentAssignmentResourceId: '=',
        checkAnswers: '=?',
        answers: '='
      },
      link: function($scope, $element, $attrs) {
        let _player = null;

        $scope.$on('playerFrame:loaded', (event, playerInstance, frameEl) => {
          event.stopPropagation();

          _player = new PlayerControls(playerInstance, frameEl);
          _player.load($scope.activeResource, $scope.user.role);
        });

        $scope.$on('playerFrame:pageLoaded', (event) => {
          event.stopPropagation();

          if (!_player) {
            return;
          }

          _player.updateUi();

          if ($scope.checkAnswers) {
            _player.checkAnswers();
          }
          
          if ($scope.ifStudent()) {
            pullScoringFromPlayer(_player);
          }

          $scope.$emit('player:pageLoaded', _player);
        });

        let load = () => {
          let resource = $scope.activeResource.resource;
          $scope.type = resource.resource_type.title;

          switch ($scope.type) {
            case 'PDF':
              changeStatusNotMauthor();
              $scope.activeResource.iframeUrl = `/build/pdf/web/viewer.html${Config.assetVersion}&file=${encodeURIComponent(resource.content)}`;
              break;
            case 'Text':
              changeStatusNotMauthor();
              break;
            case 'YouTube':
              changeStatusNotMauthor();
              $scope.activeResource.iframeUrl = resource.content.replace('watch?v=', 'embed/');
              break;
          }
        };

        let setNavigation = () => {
          $scope.ifFirst = $scope.activeResource.first;
          $scope.ifLast = $scope.activeResource.last;
        };

        let getData = (id) => {
          $http.get(`/student/assignment/${id}/resources`)
            .then((response) => {
              $scope.studentAssignmentResources = response.data;

              if ($scope.ifTeacher()) {
                $scope.studentAssignmentResources = $scope.studentAssignmentResources.filter(
                  (item) => item.resource.resource_type.title == 'mAuthor'
                );
              }

              const assignmentsLength = $scope.studentAssignmentResources.length;

              if (assignmentsLength) {
                $scope.studentAssignmentResources[0].first = true;
                $scope.studentAssignmentResources[assignmentsLength - 1].last = true;
                $scope.activeResource = $scope.studentAssignmentResources[0];
                $scope.activeId = 0;

                if ($scope.ifStudent()) {
                  $scope.activeResource = $filter('getById')(
                    $scope.studentAssignmentResources,
                    $scope.studentAssignmentResourceId
                  );

                  $scope.activeId = $scope.studentAssignmentResources.indexOf(
                    $scope.activeResource
                  );
                }

                load();
                setNavigation();

                $scope.showSubmit = () => {
                  return $scope.ifStudent() && $scope.activeResource &&
                    !$scope.activeResource.submitted &&
                    $scope.type === 'mAuthor';
                };
              }
            }, console.error);
        }; 

        let getAnswers = (assignmentStudentId, studentAssignmentResourceId) => {
         $http.get(`/lesson/${assignmentStudentId}/${studentAssignmentResourceId}/answers`)
            .then((response) => {
              $scope.answers= response.data; 
              if ($scope.answers && Object.keys($scope.answers).length > 0) {
                document.body.classList.add("showed-player-user-answers");
                pullScoringFromPlayer(_player);
              }
            })
            .catch(error => {
              //
            });
        };

        const changeStatusNotMauthor = () => {
          $http.put(`/student/lesson/${$scope.activeResource.id}/resource/status`)
            .catch(console.error);
        };

        const init = () => {
          getData($scope.id);          
          if ($scope.ifStudent()) {
            getAnswers($scope.id, $scope.studentAssignmentResourceId);
          }
        };

        $scope.selectResource = (id) => {
          $scope.activeResource = $scope.studentAssignmentResources.filter(
            (item) => item.id === id
          ).pop();

          setNavigation();
          load();
        };

        $scope.next = () => {
          $scope.activeId += 1;
          $scope.activeResource = $scope.studentAssignmentResources[$scope.activeId];
          load();
          setNavigation();
        };

        $scope.prev = () => {
          $scope.activeId -= 1;
          $scope.activeResource = $scope.studentAssignmentResources[$scope.activeId];
          load();
          setNavigation();
        };

        $scope.submit = () => {
          _player.saveState()
            .then(() => {
              const scoreArray = _player.getScore();

              if (!scoreArray) {
                alertProvider.error('To submit you have to fill every answer.');
                return;
              }

              $http.post(`/student/lesson/${$scope.activeResource.id}/resource/submit`, scoreArray)
                .then((response) => {
                  $scope.activeResource.submitted = true;

                  $window.location.href = `//${$window.location.host}/today-lesson`;
                })
                .catch(console.error);
            });
        };

        const pullScoringFromPlayer = (_instance) => {
          let instance = null;
          if (!_instance) {
            return;
          }

          if(_instance.playerInstance) {
            instance = _instance.playerInstance;
          }
          if (!instance) {
            return;
          }
      
          const isScored = ({score, checkCount, errorCount}) => (score || checkCount || errorCount);
          const ps = instance.getPlayerServices();
          const model = ps.getPresentation();
          const scoreService = ps.getScore();
          const scoreMap = Array.from(Array(model.getPageCount()), (_, i) => {
            const page = model.getPage(i);
            const content = i + 1;
      
            if(!page.isReportable()){
              return {content, score: 0, isScored: false};
            }
      
            const item = scoreService.getPageScoreById(page.getId());
            return {content, score: item.score, isScored: isScored(item)};
          });

          scoreMap.forEach(({content, score, isScored}) => {
            const rubric = $scope.answers.questions.find(item => item.content.toString() === content.toString());
            if(rubric && rubric.auto_scored) {     
              if (isScored) {
                rubric.checked = score > 0;
              } else {
                rubric.checked = 0;
              }  
            }
          });
      
          $scope.$apply();    
        };

        $scope.ifStudent = () => {
          return $scope.user.role === 'Student';
        };

        $scope.ifTeacher = () => {
          return $scope.user.role === 'Teacher';
        };

        $scope.ifScored = () => {          
          let result = false;
          if($scope.activeResource && $scope.activeResource.scored === true) {
            result = true;
          }
          return result;
        };

        $scope.ifShowAnswers = () => {
          let result = true;
          if (!$scope.ifStudent()) {
            result = false;
          }
          if (!$scope.ifScored()) {
            result = false;
          }
          if (!$scope.answers) {
            result = false;
          } else {
            if(Object.keys($scope.answers).length === 0) {
              result = false;
            }
          }
          return result;
        };

        init();
      },
    };
  }]);
