spApp.directive('cleverDirective', [
  '$window',
  ($window) => ({
    restrict: 'E',
    templateUrl: '/views/clever.html',
    transclude: true,
    scope: {
      active: '<?',
      loading: '<?',
      href: '<?',
      ngClass: '<?'
    },
    link: ($scope, element) => {
      $scope.loading = ($scope.loading === undefined)
        ? false
        : $scope.loading;

      $scope.active = ($scope.active === undefined)
        ? true
        : $scope.active;

      $scope.ngClass = {
        disabled: !$scope.active,
        ...($scope.ngClass === undefined ? {} : $scope.ngClass)
      };

      element.click(() => {
        if (!$scope.href) {
          return;
        }

        if (!$scope.active) {
          return;
        }

        $scope.loading = true;
        $scope.active = false;

        $scope.$apply();

        setTimeout(() => {
          $window.location.href = $scope.href;
        }, 500);
      });
    }
  })
]);
