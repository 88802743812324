spApp.directive('comingSoonDirective', ['$http', '$uibModal', function ($http, $uibModal) {
    return {
      transclude: true,
      restrict: 'E',
      templateUrl: '/views/directives/coming-soon-directive.html',
      scope: {
        name: '@',
        confirmFn: '&',
        type: '@'
      },
      link: function ($scope) {
        $scope.openModal = () => {
          let modalInstance = $uibModal.open({
            animation: true,
            templateUrl: '/views/coming-soon.html',
            size: 'md',
            controller: [
              '$scope', '$uibModalInstance',
              ($scope, $uibModalInstance) => {
                $scope.confirm = () => {
                  $uibModalInstance.close();
                };
                $scope.cancel = () => {
                  $uibModalInstance.dismiss();
                };
              }
            ],
            resolve: {
            }
          });
          modalInstance.result.then(() => {
            $scope.confirmFn();
          }, () => {
          });
        };
      }
    };
  }]);
