spApp.controller('GroupEditController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', 'lodash', 'group', 'type',
  function ($scope, $http, $uibModalInstance, alertProvider, lodash, group, type) {
    $scope.elements = [];
    $scope.students = [];
    $scope.loading = true;
    $scope.group = group 
      ? { ...group }
      : {
        subject_id: null,
        grade_id: null,
        id: null
      };

    $scope.type = type.type;

    $scope.$watchGroup(['group.subject_id', 'group.grade_id'], () => {
      $scope.students = $scope.students.map(student => ({
        ...student,
        selected: false
      }));
    });

    const extractData = response => response.data;
    const getGrades = () => $http.get('/grades/list').then(extractData);
    const getSubjects = () => $http.get('/subjects/list').then(extractData);
    const getStudents = () => $http
      .get('/students/filter', {
        params: { total: true }
      })
      .then(extractData);

    const groupHasStudent = ({ id }) => $scope.group.students.some(item => item.id === id);
      
    const getData = () => Promise.all([getGrades(), getSubjects()])
      .then(([grades, subjects]) => {
        $scope.grades = grades;
        $scope.subjects = subjects;

        if ($scope.grades.length === 1) {
          $scope.group.grade_id = $scope.grades[0].id;
        }

        if ($scope.subjects.length === 1) {
          $scope.group.subject_id = $scope.subjects[0].id;
        }
      })
      .then(getStudents)
      .then(students => {
        $scope.students = students.map(student => ({
          ...student,
          grades: [student.grade],
          selected: $scope.group.id 
            ? groupHasStudent(student)
            : false
        }))
      })
      .catch(error => {
        console.error(error)
      });

    const init = () => {
      getData().then(() => {
        $scope.loading = false;

        // dunno why, but this has to be delayed
        setTimeout(() => {
          $scope.$apply();
        }, 100)
      });
    };

    const saveGroupTeacher = () => {
      $scope.group.selectedStudents = $scope.students
        .filter(item => item.selected)
        .map(item => item.id);

      $http.post('/group', $scope.group)
        .then((response) => {
          alertProvider.success('Group added.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      saveGroupTeacher();
    };

    $scope.delete = () => {
      $http.delete('/groups/' + $scope.group.id)
        .then((response) => {
          alertProvider.success('Group deleted.');
          $uibModalInstance.close(response.data);
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    init();
}]);
