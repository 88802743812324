spApp.controller('RubricEditController', [
  '$scope', '$http', 'alertProvider',
  function ($scope, $http, alertProvider) {
    $scope.rubricCriteria = [];
    $scope.rubricCriteriaData = [];
    $scope.rubric = {};

    $scope.options = {
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['alignment', ['ul', 'ol']],
        ['font',['strikethrough','superscript']],
        ['insert',['picture']]
      ],
    };

    $scope.getRubric = () => {
      $http.get(`/rubrics/${$scope.rubricId}`)
        .then((response) => {
          $scope.rubric = response.data;
        })
        .catch((errorResponse) => {
          alertProvider.responseError(errorResponse);
        });
    };

    $scope.getCriteria = () => {
      return $http.get(`/rubrics/${$scope.rubricId}/criteria`)
        .then((response) => {
          $scope.rubricCriteria = response.data;
          $scope.rubricCriteriaData = angular.copy($scope.rubricCriteria);
        })
        .catch((errorResponse) => {
          alertProvider.responseError(errorResponse);
        });
    };

    $scope.editCriteria = () => {
      const width = angular.element('.criteria-editor').width();
      $scope.options.width = width;
    };

    $scope.cancelEdit = () => {
      $scope.resetCriteria();
    };

    $scope.resetCriteria = () => {
      $scope.rubricCriteria = angular.copy($scope.rubricCriteriaData);
    }

    $scope.toggleEdit = (index) => {
      $scope.resetCriteria();
      $scope.rubricCriteria[index].inEdit = true;
    };

    $scope.save = () => {
      $http.put(`/rubrics/${$scope.rubric.id}`, {rubric: $scope.rubric})
        .then((response) => {
          alertProvider.success('Rubric title saved.');
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.saveCriteria = (criteria) => {
      criteria.inEdit = false;

      return $http.post('/rubrics/criteria', {criteria})
        .then((response) => {
          alertProvider.success('Rubric criteria saved.');
          return $scope.getCriteria();
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };
    $scope.deleteCriteria = (criteria) => {
      $http.delete(`/rubrics/criteria/${criteria.id}`)
        .then((response) => {
          alertProvider.success('Rubric criteria deleted.');
          $scope.getCriteria();
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.add = () => {
      if ($scope.rubricCriteria.length === 10){
        alertProvider.error("You can only add up to 10 questions.")
        return;
      }

      const newCriteria = {
        id: null,
        content: '',
        correct: '',
        incorrect: '<p>Incorrect</p>',
        rubric_id: $scope.rubric.id,
        auto_scored: 0,
      };

      $scope.saveCriteria(newCriteria)
        .then(() => {
          const lastIndex = $scope.rubricCriteria.length - 1;
          $scope.rubricCriteria[lastIndex].inEdit = true;
        });
    };

    $scope.init = (rubricId) => {
      $scope.rubricId = rubricId;
      $scope.getRubric();
      $scope.getCriteria();
    };
  }]);
