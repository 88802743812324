spApp.controller('PlayerController', [
  '$scope',
  '$uibModalInstance',
  'resource',
  '$sce',
  '$http',
  'printTeacherPdf',
  function ($scope, $uibModalInstance, resource, $sce, $http, printTeacherPdf) {
    const player = {
      frameEl: null,
      instance: null,
    };

    $scope.resource = resource;
    $scope.text = null;

    $scope.$on('playerFrame:loaded', (event, instance, frameEl) => {
      event.stopPropagation();

      Object.assign(player, { instance, frameEl })
    });
    
    let youtubeIdParser = (url) => {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    };

    let youtubeEmbededUrl = (url) => {
      var youtubeId = youtubeIdParser(url);
      if(youtubeId) {
        return '//www.youtube.com/embed/'+youtubeId;
      }
      return url;
    };

    if (resource.content.includes('.pdf')) {
      $scope.url = `/build/pdf/web/viewer.html${Config.assetVersion}&file=${encodeURIComponent(resource.content)}`;
    } else if (resource.content.includes('youtube.com')) {
      $scope.url = youtubeEmbededUrl(resource.content);
    } else if (resource.title === 'Text resource') {
      $scope.text = $sce.trustAsHtml(resource.content);
    }

    $scope.checkIfMauthor = () => {
      return $scope.resource.resource_type.title === 'mAuthor';
    };

    $scope.checkIfAssessment = () => {
      if($scope.resource.resource_lesson_types && $scope.resource.resource_lesson_types[0]) {
        return $scope.resource.resource_lesson_types[0].title === 'Assessment';
      }
      return false;
    };

    $scope.checkIfDailyLesson = () => {
      if($scope.resource.resource_lesson_types && $scope.resource.resource_lesson_types[0]) {
        return $scope.resource.resource_lesson_types[0].title === 'Daily Lesson';
      }
      return false;
    };

    $scope.checkIfStandardsPlus = () => {
      if(!$scope.resource.resource_publishers) { return null; }
      if($scope.resource.resource_publishers && $scope.resource.resource_publishers[0]) {
        return $scope.resource.resource_publishers[0].title === 'Standards Plus';
      }
      return false;
    };
    
    $scope.checkIfShowPrintButton = () => {
      if($scope.checkIfMauthor() && $scope.checkIfStandardsPlus() !== false) {
        if($scope.checkIfAssessment() || $scope.checkIfDailyLesson()) {
          return true;
        }
      }
      return false;
    };

    $scope.printTeacherPage = () => {
      printTeacherPdf($scope, { player, title: $scope.resource.title })
        .catch(error => {
          console.error('Couldnt print pdf:', error);
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    $scope.displayPlayer = $scope.resource.resource_type.title === 'mAuthor';
    $scope.displayFrame = $scope.text === null && !$scope.displayPlayer;
    $scope.displayText = $scope.text !== null;
  }
]);
