spApp.directive('sortDirective', function () {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/sort-filter.html',
    scope: {
      order: '=',
    },

    link: function ($scope, element) {
      let orderBy = [];
      $scope.sorting = (by) => {
        orderBy = by
        $scope.$emit('sorting', [$scope.order, by])
      }
      $scope.$on('selectedSort', (e, selectedSort) => {
        let target = angular.element(element[0]);
        target.find('i').removeClass('sort-active');
        selectedSort.forEach(function (el) {
          if (el[0] === $scope.order && el[1] === orderBy) {
            target.find('#' + el[0] + '-' + orderBy).addClass('sort-active');
          }
        })
      })
    },
  };
});
