//Multiple sort service
//Input current selected array ['column', 'order by']
//Return multiple sorted array [['column1', 'order by1'], ['column2', 'order by2'],...]
angular.module('spApp')
  .factory('sortableProvider', [ function () {
    this.sort = [];

    return (data) => {

      if (!this.sort.some(el => el[0] === data[0])) {
        this.sort.push(data);
      } else {
        this.sort.forEach((el, index) => {
          if (el[0] === data[0] && el[1] !== data[1]) {
            this.sort[index][1] = data[1]
          } else if (el[0] === data[0] && el[1] === data[1]) {
            this.sort.splice(index, 1)
          }
        });
      }
      return this.sort
    }
  }]);
