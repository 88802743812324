spApp.factory('Groups.EditModal', [
  '$uibModal',
  function ($uibModal) {
    return (type, group) => {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/edit-group.html',
        controller: 'GroupEditController',
        size: 'md',
        resolve: {
          group: group,
          type: {type},
        }
      });

      return modalInstance.result;
    };
  }
]);
