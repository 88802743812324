spApp.controller('AssignmentStudentController', ['$scope', '$http', 'alertProvider', function ($scope, $http, alertProvider) {
  $scope.assignmentStudents = [];

  $scope.init = (id) => {
    $scope.id = id;
    $scope.getData(id);
  };

  $scope.getData = (id) => {
    $http.get('/assignments/'+id+'/students/list').then((response) => {
      $scope.assignments = response.data;
    }, (data) => {
      alertProvider.responseError(data);
    });
  };

  $scope.deleteStudentAssignment = (assignment) => {
    $http.delete('/assignments/students/'+ assignment.id).then((response) => {
      assignment.delete = true;
      $scope.getData($scope.id);
    },(data) => {
      alertProvider.responseError(data);
    });
  };
}]);