spApp.directive('resourceLessonNumberDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-lesson-number-filter.html',
    scope: {
      selectedLessonNumbers: '='
    },
    link: function ($scope) {
      $http.get('/resources/lessonNumbers').then(function (response) {
        $scope.lessonNumbers = response.data;
      }, function (data) {
        console.error(data);
      });
      $scope.lessonNumberChanged = function () {
        $scope.selectedLessonNumbers = $scope.lessonNumbers.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
