spApp.controller('ShareController', ['$scope', '$http', 'items', 'users', '$uibModalInstance', 'type',
  function ($scope, $http, items, users, $uibModalInstance, type) {
  $scope.items = items;
  $scope.users = users;
  $scope.type = type;

  $scope.cancel = () => {
    $uibModalInstance.dismiss();
  };

  $scope.save = () => {
    let usersToShareWith = $scope.users.filter((element) => {
      return element.selected;
    }).map((element) => {
      return element.id
    });

    let itemsToShare = $scope.items.filter((element) => {
      return element.selected;
    }).map((element) => {
      return element.id
    });

    $http.post('/shares/' + $scope.type, {items: itemsToShare, users: usersToShareWith})
      .then((response) => {
        $uibModalInstance.close(response.data);
      });
  };


  $scope.init = () => {
    //
  };

  $scope.init();
}]);