spApp.directive('resourcePublisherDirective', ['$http', 'loadable', ($http, loadable) => {

  const setSelectedPublisher = $scope => () => {
    const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));

    if (!urlParams.selectedPublisher) {
      return;
    }

    const selectedPublisher = urlParams.selectedPublisher;

    $scope.selectedPublisher = [selectedPublisher];
    $scope.publishers = $scope.publishers.map(publisher => ({
      ...publisher,
      selected: publisher.id == urlParams.selectedPublisher
    }))
  };

  const loadPublishers = ($http) => $http.get('/resources/publishers')
    .then(
      response => response.data,
      error => {
        console.error(error);
        return Promise.reject(error);
      }
    );

  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-publisher-filter.html',
    scope: {
      selectedPublisher: '=',
      publishersList: '=?publishers',
      loaders: '=?',
    },
    link: function ($scope) {
      const loaded = loadable($scope);

      const promise = $scope.publishersList
        ? Promise.resolve($scope.publishersList)
        : loadPublishers($http);

      promise
        .then(publishers => {
          $scope.publishers = publishers;
        })
        .then(setSelectedPublisher($scope))
        .then(() => {
          loaded();
        });

      $scope.publisherChanged = () => {
        $scope.selectedPublisher = $scope.publishers.filter(item => item.selected)
          .map(item => item.id);
      };
    }
  };
}]);


// spApp.directive('resourcePublisherDirective', ['$http', function ($http) {
//   return {
//     restrict: 'E',
//     templateUrl: '/views/filters/resource-publisher-filter.html',
//     scope: {
//       selectedPublisher: '='
//     },
//     link: function ($scope) {
//       $http.get('/resources/publishers').then(function (response) {
//         $scope.publishers = response.data;
//       }, function (data) {
//         console.error(data);
//       });
//       $scope.publisherChanged = function () {
//         $scope.selectedPublisher = $scope.publishers.filter(function (item) {
//           console.log('$scope.selectedPublisher DONE');
//           return item.selected;
//         }).map(function (item) {
//           return item.id;
//         });
//       };
//     }
//   };
// }]);
