angular.module('spApp')
  .factory('squareTile', [ function () {

    this.getColor = (item, type) => {
        console.log('item resource-->', item);
      if(type =='group'){
        return item.subject.title;
      }
      else if (type =='resource') {
        if (item.content.includes('.pdf')) {
          return 'pdfItem';
        } else {
          if(item.resource_type.title =='mAuthor'){
            return item.resource_subjects[0].title;
          }

        }
      }
      else {
        if(item.global) {
          return "orange";
        }
        if(item.shared){
          return "blue";
        }
      }

    };


    return this;
  }]);

