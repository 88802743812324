spApp.directive('resourceUsageDashboardDirective', [
  '$http', function($http) {
    return {
      restrict: 'E',
      templateUrl: '/views/directives/resource-usage-dashboard-directive.html',
      scope: {
        params: '=',

      },
      link: function($scope) {

        $scope.options = {
          chart: {
            type: 'multiBarChart',
            height: 450,
            groupSpacing: 0.6,
            showXAxis: true,
            showYAxis: true,
            margin: {
              top: 50,
              right: 20,
              bottom: 45,
              left: 45,
            },
            clipEdge: true,
            duration: 500,
            stacked: false,
            xAxis: {
              axisLabel: 'Subject',
              showMaxMin: false,
            },
            yAxis: {
              axisLabel: 'Lesson Usage',
              axisLabelDistance: -20,
              ticks: 10,
              tickFormat: function(d) {
                return d3.format('d')(d);
              },
            },
          },
        };

        $scope.$watch('params', function(newValue) {
          if (newValue) {
            $scope.getData($scope.params);
          }
        }, true);

        $scope.getData = (params) => {
          params = angular.copy(params);

          $http.get('/data-old/resources', { params })
            .then((response) => {
              $scope.resourceData = response.data;

              let resources = {
                key: 'Lessons',
                values: [],
              };
              let resourcesAssigned = {
                key: 'Lessons Assigned',
                values: [],
              };
              let resourcesScored = {
                key: 'Lessons Scored',
                values: [],
              };

              if ($scope.resourceData.Math) {
                resources.values.push(
                  {x: 'Math', y: $scope.resourceData.Math.resources.length});
                resourcesAssigned.values.push({
                  x: 'Math',
                  y: $scope.resourceData.Math.resourcesAssigned.length,
                });
                resourcesScored.values.push({
                  x: 'Math',
                  y: $scope.resourceData.Math.resourcesScored.length,
                });

              }

              if ($scope.resourceData.ELA) {
                resources.values.push(
                  {x: 'ELA', y: $scope.resourceData.ELA.resources.length});
                resourcesAssigned.values.push({
                  x: 'ELA',
                  y: $scope.resourceData.ELA.resourcesAssigned.length,
                });
                resourcesScored.values.push({
                  x: 'ELA',
                  y: $scope.resourceData.ELA.resourcesScored.length,
                });
              }
              $scope.data = [resources, resourcesAssigned, resourcesScored];

            }, (data) => {
              //
            });
        };

      },
    };
  }]);
