spApp.controller('GroupStudentsController', [
  '$scope',
  '$http',
  '$window',
  'alertProvider',
  'lodash',
  'Groups.EditModal',
  function($scope, $http, $window, alertProvider, lodash, editModal) {
    const getGroupString = lodash.flow([
      lodash.property('assignment_student_resource[0].resource.resource_standards'),
      groups => lodash.map(groups, lodash.property('title')),
      lodash.uniq,
      titles => titles.join(', ')
    ]);

    const formatGroupData = () => {
      const assignments = [];
      const cursor = {
        assignmentId: '',
        resourceId: ''
      }

      let resource = {};
      let assignment = {};

      for(let assignmentStudentResource of $scope.group.assignment_student_resource) {
        if(assignmentStudentResource.assignment_student.assignment_id != cursor.assignmentId) {
          cursor.assignmentId = assignmentStudentResource.assignment_student.assignment_id;
          assignment = {
            resources: []
          };

          assignments.push(assignment);
        }

        if(assignmentStudentResource.resource_id != cursor.resourceId) {
          cursor.resourceId = assignmentStudentResource.resource_id;
          resource = {};
          resource.students = [];
          assignment.resources.push(resource);
        }

        resource.resource = assignmentStudentResource.resource;

        resource.students.push({
          score:  (assignmentStudentResource.score + assignmentStudentResource.mauthor_score) / assignmentStudentResource.max_score,
          student: assignmentStudentResource.assignment_student.student
        });
      }

      return assignments;
    };

    $scope.getData = (id) => {
      $http.get('/group/' + id + '/students')
        .then((response) => {
          $scope.group = response.data;

          if($scope.group.type == 'reteach') {
            $scope.standardString = getGroupString($scope.group);
          }

          $scope.assignments = formatGroupData();
        },
        (errorResponse) => {
          if (errorResponse.status === 404) {
            $window.location.href = '/groups';
          } else {
            alertProvider.responseError(errorResponse);
          }
        });
    };

    $scope.init = ({ groupId, lessonsUri }) => {
      $scope.getData(groupId);
      $scope.lessonsUri = lessonsUri;

      $http.put('/group/' + groupId + '/visited')
        .then(lodash.noop, (errorResponse) => {
          alertProvider.responseError(errorResponse);
        });
    };

    $scope.editGroupModal = (type, group) => {
      editModal(type, group)
        .then(
          () => {
            $scope.getData(group.id);
          },
          lodash.noop
        );
    };
  }
]);
