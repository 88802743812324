spApp.controller('ShareAdminController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', 'user',
  function($scope, $http, $uibModalInstance, alertProvider, user) {

    let initSharedData = () => {

      for (let school of $scope.schools) {
        if ($scope.sharedIdSchools.indexOf(school.id) >= 0) {
          school.selected = true;
        }
        else {
          school.selected = false;
        }
      }

      for (let district of $scope.districts) {
        if ($scope.sharedIdDistricts.indexOf(district.id) >= 0) {
          district.selected = true;
        }
        else {
          district.selected = false;
        }
      }

    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    $scope.save = () => {
      let selectedSchools = $scope.schools.filter(item => item.selected).
        map(item => item.id);

      let selectedDistricts = $scope.districts.filter(item => item.selected).
        map(item => item.id);

      $http.post('/shares-admin', {
        item: $scope.item,
        schools: selectedSchools,
        districts: selectedDistricts,
      }).then((response) => {
        $uibModalInstance.close(response.data);
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    };

    $scope.getData = () => {

      $http.get('/districts/list').then((response) => {
        $scope.districts = response.data;
      }, (data) => {
        console.error(data);
      });
      $http.get('/schools/list').then((response) => {
        $scope.schools = response.data;
      }, (data) => {
        console.error(data);
      });

      $http.get('/instructional-sequences/to-share-admin').then((response) => {
        $scope.items = response.data;
      }, (data) => {
        console.error(data);
      });
    };

    $scope.getSharedData = () => {

      $http.get('/shares-admin/' + $scope.item.id).then((response) => {
        let shares = response.data;
        $scope.sharedIdDistricts = shares.filter(item => item.share_to_type == 'district').map(item => item.share_to_id);
        $scope.sharedIdSchools = shares.filter(item => item.share_to_type == 'school').map(item => item.share_to_id);
        initSharedData();
      }, (data) => {
        console.error(data);
      });
    };



    $scope.selectedItem = function() {
      $scope.getSharedData();
    };

    $scope.init = () => {
      $scope.user = user;
      $scope.getData();

    };

    $scope.init();
  }]);
