spApp.controller('UserController', [
  '$scope', '$http', '$uibModal', 'alertProvider', '$window',
  function ($scope, $http, $uibModal, alertProvider, $window) {
    $scope.data = {};
    $scope.loading = false;
    $scope.selectedSchools = [];
    $scope.selectedDistricts = [];
    $scope.selectedSubjects = [];
    $scope.selectedGrades = [];
    $scope.selectedStates = [];
    $scope.savedUser = null;
    $scope.filters = {
      email: '',
      name: '',
      role: '',
      sort: []
    };
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0
    };
    $scope.editedUser = {};
    $scope.roles = [];
    $scope.states = [];
    $scope.districts = [];
    $scope.schools = [];
    $scope.clever = {
      schoolId: null,
      loading: false,
      sync: {
        allow_sync: false
      }
    };
    $scope.grades = [];
    $scope.subjects = [];

    $scope.$watchGroup(['selectedSchools', 'selectedDistricts', 'selectedSubjects', 'selectedGrades', 'selectedStates'], () => {
      $scope.getData();
    });

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    //Sorting users table
    $scope.$on('sorting', (event, data) => {
      $scope.filters.sort = [data];
      $scope.getData();
      $scope.$broadcast('selectedSort', $scope.filters.sort)
    })

    $scope.getData = (page) => {
      let params = {
        email: $scope.filters.email,
        name: $scope.filters.name,
        role: $scope.filters.role,
        perPage: $scope.paging.perPage,
        'districts[]': $scope.selectedDistricts,
        'schools[]': $scope.selectedSchools,
        'subjects[]': $scope.selectedSubjects,
        'grades[]': $scope.selectedGrades,
        'states[]': $scope.selectedStates,
        'sort[]': $scope.filters.sort,
      };

      // console.log(params)
      page ? params.page = page : true;
      $scope.loading = true;
      $http.get('/users/filter', {params: params}).then((response) => {
        $scope.data = response.data;
        $scope.getPagingData(response.data);
      }, (data) => {
        console.error(data);
      }).finally(() => {
        $scope.loading = false;
      });

      if(!$scope.savedUser) {
        $http.get('/roles/less').then((response) => {
          $scope.roles = response.data;
        });

        $http.get('/states/list').then((response) => {
          $scope.states = response.data;
        });

        $http.get('/districts/list').then((response) => {
          $scope.districts = response.data;
        });

        $http.get('/schools/list').then((response) => {
          $scope.schools = response.data;
        });
      }

      if($scope.savedUser) {
        $scope.editUser($scope.savedUser);
      }
    };

    $scope.getPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.editUser = (user) => {
      if($scope.savedUser) {
        $scope.savedUser = null;
      }
      $scope.editedUser = angular.copy(user);
      $scope.formatGrades($scope.editedUser);
      $scope.formatSubjects($scope.editedUser);
    };

    $scope.formatGrades = (user) => {
      let userGradesIds = user.grades.map((element) => {
        return element.id;
      });
      $scope.grades = $scope.grades.map((element) => {
        element.selected = userGradesIds.indexOf(element.id) !== -1;
        return element;
      });
    };

    $scope.formatSubjects = (user) => {
      let userSubjectsIds = user.subjects_conducted.map((element) => {
        return element.id;
      });
      $scope.subjects = $scope.subjects.map((element) => {
        element.selected = userSubjectsIds.indexOf(element.id) !== -1;
        return element;
      });
    };

    $scope.cancelUserEdit = () => {
      $scope.editedUser = {};
      $scope.grades = $scope.grades.map((element) => {
        element.selected = false;
        return element;
      });
      $scope.subjects = $scope.subjects.map((element) => {
        element.selected = false;
        return element;
      });
    };

    $scope.save = () => {
      $scope.addSubjectsToUser();
      $scope.addGradesToUser();
      $http.post('/users', $scope.editedUser).then(
        (response) => {
          alertProvider.success('User saved.');
          if(response && response.data && response.data.id) {
            $scope.savedUser = response.data;
          } else {
            $scope.cancelUserEdit();
          }
          $scope.init();
        }, (response) => {
          if(response && response.data && response.data.error) {
            alertProvider.error(response.data.error);
          }
        }
      );
    };

    $scope.addSubjectsToUser = () => {
      $scope.editedUser.grades = $scope.grades.filter((element) => {
        return element.selected;
      });
    };

    $scope.addGradesToUser = () => {
      $scope.editedUser.subjects = $scope.subjects.filter((element) => {
        return element.selected;
      });
    };

    $scope.getGradeData = () => {
      $http.get('/grades/list').then((response) => {
        $scope.grades = response.data;
      }, (data) => {
        console.error(data);
      });
    };

    $scope.getSubjectsData = () => {
      $http.get('/subjects/list').then((response) => {
        $scope.subjects = response.data;
      }, (data) => {
        console.error(data);
      });
    };

    $scope.resetSchool = () => {
      $scope.editedUser.school_id = null;
    };

    $scope.init = (cleverDetails) => {      
      if(!$scope.savedUser) {
        $scope.getGradeData();
        $scope.getSubjectsData();
      }
      $scope.getData();

      if(cleverDetails) {
        $scope.clever.sync = cleverDetails;

        if ($scope.clever.sync.sync_running) {
          setInterval(() => {
            $window.location.reload()
          }, 5000)
        }
      }
    };

    $scope.delete = () => {
      $http.delete('/users/' + $scope.editedUser.id).then((response) => {
        alertProvider.success('User deleted.');
        $scope.init();
        $scope.cancelUserEdit();
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    };

    $scope.startCleverSync = () => {
      $scope.clever.loading = true;

      $http.post('/_clever/sync')
        .then(() => {
          $window.location.reload();
        })
        .catch(error => {
          alertProvider.responseError(error);
        });
    };
  }
]);
