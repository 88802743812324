spApp.controller('RubricAddController', [
  '$scope', '$http', '$uibModalInstance', 'alertProvider', '$window',
  function ($scope, $http, $uibModalInstance, alertProvider, $window) {
      $scope.rubric = {
        title:''
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss();
      };

      $scope.save = () => {
        $http.post('/rubrics', $scope.rubric).then((response) => {
          alertProvider.success('Rubric saved.');
          $uibModalInstance.close(response.data);
          $window.location.href = "rubrics/edit/" + response.data.id;
        }).catch((response) => {
          alertProvider.responseError(response);
        });
      };

      $scope.getData = () => {

        $http.get('/resources/list').then((response) => {
          $scope.resources = response.data;
        }, (data) => {
          console.error(data);
        });
      };

      $scope.copyTitle = () => {
        $scope.rubric.title = angular.copy($scope.rubric.resource.title) + ' - Rubric';
      };

      let init = () => {

        $scope.getData();
      };
      init();
  }
]);
