angular.module('spApp')
  .factory('alertProvider', ['toastr', function (toastr) {
    this.error = function (message) {
      return toastr.error(message, '', {
        extendedTimeOut: 30000,
        timeOut: 30000
      });
    };

    this.responseError = function (response) {
      return toastr.error(response.data.error, '', {
        extendedTimeOut: 30000,
        timeOut: 30000
      });
    };

    this.success = function (message) {
      return toastr.success(message);
    };

    this.responseSuccess = function (response) {
      return this.success(response.data.message);
    };

    this.info = function (message) {
      return toastr.info(message);
    };

    this.error = function (message) {
      return toastr.error(message);
    };

    return this;
  }]);
