spApp.directive('shareDirective', ['$http', '$uibModal', function ($http, $uibModal) {
  return {
    restrict: 'E',
    templateUrl: '/views/directives/share-directive.html',
    scope: {
      type: '=',
    },
    link: ($scope) => {
      $scope.addNewShare = () => {
        $scope.getUsersICanShareWith()
          .then((results) => {
            $scope.usersICanShareWith = results.data;
            return $scope.getItemsICanShare();
          })
          .then((results) => {
            $scope.itemsICanShare = results.data;
            $scope.openShareModal();
          });
      };

      $scope.getItemsICanShare = () => {
        return $http.get('/' + $scope.type + '/to-share');
      };

      $scope.getUsersICanShareWith = () => {
        return $http.get('/users/to-share-with');
      };

      $scope.openShareModal = () => {
        let modalInstance = $uibModal.open({
          animation: true,
          templateUrl: '/views/share.html',
          controller: 'ShareController',
          size: 'lg',
          resolve: {
            items: () => {
              return $scope.itemsICanShare;
            },
            users: () => {
              return $scope.usersICanShareWith;
            },
            type: () => {
              return $scope.type
            }
          }
        });

        modalInstance.result.then(() => {
          //
        }, () => {

        });
      };
    }
  };
}]);
