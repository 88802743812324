spApp.directive('iframeOnload', [
  function() {
    return {
      restrict: 'A',
      scope: {
        callback: '&iframeOnload'
      },
      link: function(scope, element){
        element.on('load', function(){
          return scope.callback();
        })
      }
    }
  }
])
