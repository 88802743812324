spApp.directive('previewDirective', [
  '$http',
  'lodash',
  'printTeacherPdf',
  function ($http, lodash, printTeacherPdf) {
    return {
      transclude: true,
      restrict: 'E',
      templateUrl: '/views/preview-directive.html',
      scope: {
        lessonSequenceId: '=',
        teachMode: '@',
      },
      link: function ($scope) {
        const player = {
          frameEl: null,
          instance: null,
        };

        $scope.$on('playerFrame:loaded', (event, instance, frameEl) => {
          console.log('playerFrame:loaded')
          event.stopPropagation();

          Object.assign(player, { instance, frameEl })
        });

        $scope.showedMenu = false;
        $scope.preview = (id) => {
          $http.get(`/instructional-sequences/${id}`)
            .then((response) => {
              $scope.lessonSequence = response.data;
              update();

              $scope.activePreviewItem = lodash.get($scope.lessonSequence, 'resources[0]', null);

              console.log('activePreviewItem', $scope.activePreviewItem)

              if ($scope.activePreviewItem) {
                checkForEmbed();
                $scope.previewVisible = true;
              }
            })
            .catch(console.error);
        };

        $scope.enablePreview = () => {
          $scope.preview($scope.lessonSequenceId);
        };

        $scope.checkIfMauthor = () => {
          return $scope.activePreviewItem.resource_type.title === 'mAuthor';
        };
    
        $scope.checkIfAssessment = () => {
          if($scope.activePreviewItem.resource_lesson_types && $scope.activePreviewItem.resource_lesson_types[0]) {
            return $scope.activePreviewItem.resource_lesson_types[0].title === 'Assessment';
          }
          return false;
        };
    
        $scope.checkIfDailyLesson = () => {
          if($scope.activePreviewItem.resource_lesson_types && $scope.activePreviewItem.resource_lesson_types[0]) {
            return $scope.activePreviewItem.resource_lesson_types[0].title === 'Daily Lesson';
          }
          return false;
        };
    
        $scope.checkIfStandardsPlus = () => {
          if(!$scope.activePreviewItem.resource_publishers) { return null; }
          if($scope.activePreviewItem.resource_publishers && $scope.resource.resource_publishers[0]) {
            return $scope.activePreviewItem.resource_publishers[0].title === 'Standards Plus';
          }
          return false;
        };
        
        $scope.checkIfShowPrintButton = () => {
          if($scope.checkIfMauthor() && $scope.checkIfStandardsPlus() !== false) {
            if($scope.checkIfAssessment() || $scope.checkIfDailyLesson()) {
              return true;
            }
          }
          return false;
        };

        let checkYoutubeEmbed = () => {
          if ($scope.activePreviewItem.resource_type.title === 'YouTube') {
            $scope.activePreviewItem.iframeUrl = $scope.activePreviewItem.content.replace('watch?v=', 'embed/');
          }
        };

        let checkPdfEmbed = () => {
          if ($scope.activePreviewItem.resource_type.title === 'PDF') {
            $scope.activePreviewItem.iframeUrl = '/build/pdf/web/viewer.html' + Config.assetVersion + '&file=' + encodeURIComponent($scope.activePreviewItem.content);
          }
        };

        let checkForEmbed = () => {
          checkYoutubeEmbed();
          checkPdfEmbed();
        };

        $scope.loadPreview = (item) => {
          $scope.activePreviewItem = item;
          checkForEmbed();
        };

        $scope.nextPreviewItem = () => {
          let index = $scope.lessonSequence.resources.findIndex(elem => elem.id === $scope.activePreviewItem.id);
          let nextIndex = index + 1;
          if (nextIndex > $scope.lessonSequence.resources.length - 1) {
            return false;
          }

          $scope.loadPreview($scope.lessonSequence.resources[nextIndex]);
        };

        $scope.prevPreviewItem = () => {
          let index = $scope.lessonSequence.resources.findIndex(elem => elem.id === $scope.activePreviewItem.id);
          let nextIndex = index - 1;
          if (nextIndex < 0) {
            return false;
          }

          $scope.loadPreview($scope.lessonSequence.resources[nextIndex]);
        };

        $scope.printTeacherPage = () => {
          printTeacherPdf($scope, { player, title: $scope.activePreviewItem.title })
            .catch(error => {
              console.error('Couldnt print pdf:', error);
            });
        };

        let groupLessonSet = () => {
          let initialLessonSetId = $scope.lessonSequence.resources[0]['lesson_set_id'];
          let index = 0;
          $scope.resources = [];
          let lessonSet = [];
          angular.forEach($scope.lessonSequence.resources, function (resource) {
            if (resource['lesson_set_id'] !== initialLessonSetId) {
              $scope.resources.push(lessonSet);
              initialLessonSetId = resource['lesson_set_id'];
              lessonSet = [];
              lessonSet.push(resource);
              index += 1;
            } else {
              lessonSet.push(resource);
            }
          });
          $scope.resources.push(lessonSet);
        };

        const getAssignedResourceFromLessonSequence = () => {
          $http.get(`/assignments/lesson-sequence-assigned-resources/${$scope.lessonSequenceId}`)
            .then((response) => {
              let assignedResources = response.data;

              if(!$scope.lessonSequence.resources){
                return;
              }

              for (let resource of $scope.lessonSequence.resources) {
                if(!assignedResources.length) {
                  resource.assigned = false;
                  continue;
                }

                for (let assignedResource of assignedResources) {
                  if (assignedResource.resource_id != resource.id) {
                    continue;
                  }

                  resource.assigned = true;

                  if(!assignedResource.submitted){
                    continue;
                  }

                  resource.submitted = true;

                  if(assignedResource.scored){
                    resource.scored = true;
                  }
                }
              }
            }, (data) => {
              console.error(data);
            });
        };

        const update = () => {
          getAssignedResourceFromLessonSequence();
        };

        $scope.showMenu = () => {
          update();
          $scope.showedMenu = !$scope.showedMenu;
          console.log($scope.showedMenu);
        };
      }
    };
  }
]);
