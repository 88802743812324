spApp.directive('subjectDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/subjects-filter.html',
    scope: {
      selectedSubjects: '='
    },
    link: function ($scope) {
      $http.get('/subjects/list').then(function (response) {
        $scope.subjects = response.data;
      }, function (data) {
        console.error(data);
      });
      $scope.subjectsChanged = function () {
        $scope.selectedSubjects = $scope.subjects.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
