spApp.directive('resourceSequenceTypeDirective', [
  '$http', function($http) {
    return {
      restrict: 'E',
      templateUrl: '/views/filters/resource-sequence-type-filter.html',
      scope: {
        selectedSequenceTypes: '=',
      },
      link: function($scope) {

        $scope.resourceSequenceTypes = [
          {
            'title': 'Teacher',
            'id': 'owner',
          },
          {
            'title': 'Shared',
            'id': 'shared',
          },
          {
            'title': 'Standards Plus',
            'id': 'global',
          }];

        $scope.typeChange = function() {
          $scope.selectedSequenceTypes = $scope.resourceSequenceTypes.filter(
            function(item) {
              return item.selected;
            }).map(function(item) {
            return item.id;
          });
        };
      },
    };
  }]);
