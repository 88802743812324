spApp.directive('lessonScoreDashboardDirective', ['$http', 'lodash', function ($http, _) {
  return {
    restrict: 'E',
    templateUrl: '/views/directives/lesson-score-dashboard-directive.html',
    scope: {
      params: '=',
      title: '@'
    },
    link: function ($scope) {
      $scope.options = {
        chart: {
          yDomain: [0, 100],
          type: 'multiBarChart',
          height: 450,
          margin: {
            top: 20,
            right: 20,
            bottom: 45,
            left: 45
          },
          clipEdge: true,
          duration: 500,
          stacked: false,
          showXAxis: true,
          showYAxis: true,
          showControls: false,
          reduceYTicks:true,
          reduceXTicks:true,
          xAxis: {
            axisLabel: 'Subject',
            showMaxMin: false,
            tickFormat: function(d){
              return null;
            }
          },
          yAxis: {
            axisLabel: 'Score',
            axisLabelDistance: 50,
            tickFormat: function(d){
              d3.select(".lesson-score .nv-legendWrap").attr("transform", "translate(20,400)");
              return d3.format(',.1f%')(d)+'%';
            }
          }
        }
      };

      $scope.selectedStandards = [];
      $scope.selectedFocus = [];
      $scope.selectedStrands = [];
      $scope.selectedLessonTypes = [];

      $scope.$watchGroup(['params', 'selectedFocus', 'selectedStandards', 'selectedStrands',
        'selectedLessonTypes'], () => {
        $scope.setParams();
      });

      $scope.$watch("params", function (newValue) {
        if (newValue) {
          $scope.getScoresData();
        }
      }, true);

      $http.get('/resources/foci').then(function (response) {
        $scope.foci = response.data;
      }, function (data) {
        console.error(data);
      });

      $scope.focusChange = function () {
        $scope.selectedFocus = $scope.foci.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };

      $http.get('/resources/standards').then(function (response) {
        $scope.standards = response.data;
      }, function (data) {
        console.error(data);
      });

      $scope.standardChange = function () {
        $scope.selectedStandards = $scope.standards.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };

      $http.get('/resources/strandDomain').then(function (response) {
        $scope.strands = response.data;
      }, function (data) {
        console.error(data);
      });

      $scope.strandChange = function () {
        $scope.selectedStrands = $scope.strands.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };

      $http.get('/resources/lessonTypes').then(function (response) {
        $scope.lessonTypes = response.data;
        angular.forEach($scope.lessonTypes, function (lessonType) {
          if(lessonType.title === "Assessment") {
            lessonType.selected = true;
            $scope.selectedLessonTypes.push(lessonType.id);
          }
        })
      }, function (data) {
        console.error(data);
      });

      $scope.typeChange = function () {
        $scope.selectedLessonTypes = $scope.lessonTypes.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };

      $scope.getScoresData = _.debounce(() => {
        $http.get('/data-old/scores', {params: $scope.params}).then((response) => {
          $scope.scoresData = response.data;
          let finalArray = [];

          let scoresMath = {
            key: 'Math',
            values: [
              {x: 1, y: $scope.scoresData.mathPercentData}
            ]
          };

          let scoresEla = {
            key: 'ELA',
            values: [
              {x: 1, y: $scope.scoresData.elaPercentData}
            ]
          };

          if($scope.scoresData.mathPercentData) {
            finalArray.push(scoresMath);
          }

          if($scope.scoresData.elaPercentData) {
            finalArray.push(scoresEla);
          }

          $scope.data = finalArray;
        });
      }, 500);

      $scope.setParams = () => {
        if (!$scope.params) {
          return;
        }

        $scope.params['selectedStandards[]'] = $scope.selectedStandards;
        $scope.params['selectedLessonTypes[]'] = $scope.selectedLessonTypes;
        $scope.params['selectedStrandDomains[]'] = $scope.selectedStrands;
        $scope.params['selectedFoci[]'] = $scope.selectedFocus;
      }
    }
  };
}]);
