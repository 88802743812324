spApp.controller('SequenceCategoryController', [
  '$scope', '$http', '$uibModal', 'alertProvider', 'lodash',
  function ($scope, $http, $uibModal, alertProvider, lodash) {
    $scope.categories = [];
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0
    };

    $scope.filters = {
      title: ''
    };

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    $scope.getData = (page) => {
      const params = {
        title: $scope.filters.title,
        perPage: $scope.paging.perPage,
        page
      };

      return $http.get('/sequence-categories/filter', { params })
        .then(
          (response) => {
            $scope.categories = response.data.data;
            $scope.updatePagingData(response.data);
          },
          (data) => {
            console.error(data);
          }
        );
    };

    $scope.deleteCategory = (id) => {
      $http.delete(`/sequence-categories/${id}`)
        .then((response) => {
          alertProvider.success('Category removed.');
          $scope.getData();
        })
        .catch((response) => {
          alertProvider.responseError(response);
        });
    };

    $scope.openEditModal = categoryId => {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/add-sequence-category.html',
        controller: 'SequenceCategoryAddController',
        size: 'md',
        resolve: {
          category: categoryId
            ? $scope.categories.find(({ id }) => id === categoryId)
            : null
        }
      });

      modalInstance.result
        .then(() => {
          $scope.getData();
        })
        .catch(lodash.noop);
    };

    $scope.updatePagingData = ({ from, to, total, current_page: currentPage, last_page: lastPage }) => {
      $scope.paging = {
        ...$scope.paging,
        from,
        to,
        total,
        currentPage,
        lastPage
      };
    };

    $scope.init = () => {
      $scope.getData();
    };
  }
]);
