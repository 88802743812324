spApp.directive('resourceSubjectDirective', ['$http', 'loadable', ($http, loadable) => {
  const setSelectedSubject = $scope => () => {
    const urlParams = Qs.parse(window.location.search.replace(/^\?/, ''));

    if (!urlParams.selectedSubject) {
      return;
    }

    const selectedSubject = urlParams.selectedSubject;

    $scope.selectedSubject = [selectedSubject];
    $scope.subjects = $scope.subjects.map(subject => ({
      ...subject,
      selected: subject.id == urlParams.selectedSubject
    }))
  };

  const loadSubjects = ($http) => $http.get('/resources/subjects')
    .then(
      response => response.data,
      error => {
        console.error(error);
        return Promise.reject(error);
      }
    );

  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-subject-filter.html',
    scope: {
      selectedSubject: '=',
      subjectsList: '=?subjects',
      loaders: '=?',
    },
    link: function ($scope) {
      const loaded = loadable($scope);

      $scope.hash = window.location.hash.substr(1).toLowerCase();

      const promise = $scope.subjectsList
        ? Promise.resolve($scope.subjectsList)
        : loadSubjects($http);

      promise
        .then(subjects => {
          $scope.subjects = subjects;

          $scope.subjects.forEach((el) => {
            if ($scope.hash === el.title.toLowerCase()) {
              $scope.selectedSubject = [el.id];
              el.selected = true;
            }
          })
          $scope.hash = '';
          window.location.hash = '';
        })
        .then(setSelectedSubject($scope))
        .then(() => {
          loaded();
        });

      $scope.subjectChanged = () => {
        $scope.selectedSubject = $scope.subjects.filter(item => item.selected)
          .map(item => item.id);
      };
    }
  };
}]);
