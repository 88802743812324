spApp.directive('schoolSearchDirective', ['$http', function ($http) {
    return {
      restrict: 'E',
      templateUrl: '/views/school-search.html',
      scope: {
        states: '<',
        districts: '<',
        schools: '<',
        grades: '<',
        teachers: '<',
        selectedDict: '=',
        userrole: '=',
        districtId: '=',
        forceSchool: '='
      },
      link: function ($scope, element, attrs) {
        $scope.selected = {
          state: null,
          district: null,
          school: null,
          grade: null,
          teacher: null
        };

        $scope.setDistrictByID = (what) => {
          let sDistrict = null;
          if(typeof $scope.districts != 'undefined' && $scope.districts) {
            for (var stateDistricts in $scope.districts) {
              if($scope.districts[stateDistricts]) {                
                for (var d in $scope.districts[stateDistricts]) {
                  if($scope.districts[stateDistricts][d].id === what) {
                    sDistrict = $scope.districts[stateDistricts][d];
                    break;
                  }
                }
              }
              if(sDistrict !== null) {
                break;
              }
            }
          }
          $scope.selected.district = sDistrict;
        }

        $scope.user = null;
        $scope.schoolTeachers = [];
        $scope.user = attrs.user;

        $scope.$watch('selected.state', () => {        
          if($scope.userrole !== 'District Administrator') {
            $scope.selected.district = null;
          }
        });

        $scope.$watch('selected.district', () => {
          $scope.selected.school = null;
        });

        $scope.$watch('selected.school', () => {
          if ($scope.isSelected('school') && $scope.userrole !== 'School Administrator' && $scope.userrole !== 'Teacher') {
            $scope.selected.grade = $scope.selectedDict.grade = null;
            $scope.selected.teacher = $scope.selectedDict.teacher = null;
            $scope.selectedDict.school = $scope.selected.school;
            $scope.getGrades($scope.selected.school.id);
            $scope.getTeachers($scope.selected.school.id);
          }
        });

        $scope.changed = (what) => {
          angular.isObject($scope.selected[what]);

          switch (what) {
            case 'grade':
              $scope.selected.teacher = $scope.selectedDict.teacher = null;

              $scope.selectedDict.teacher = $scope.schoolTeachers.filter(function (teacher) {
                let g = 0;

                if (teacher.grades) {
                  teacher.grades.forEach(function(grade) {
                    if (grade.id === $scope.selected.grade) {
                      g++;
                    }
                  })
                }

                if (g > 0) {
                  return teacher;
                }
                g = 0;
              });

              $scope.selectedDict.grade = $scope.selected.grade;
              $scope.teachers = $scope.selectedDict.teacher;
              // console.log('grade', $scope.selectedDict.teacher.filter(teacher => teacher.id))

              $scope.$emit('selectedGradeSchool', {
                district: $scope.selectedDict.district || null,
                teacher: $scope.selectedDict.teacher.map(teacher => teacher.id) || null,
                school: $scope.selectedDict.school || null,
                grade: $scope.selectedDict.grade || null,
              })
              break;
          }
        }

          $scope.$watch('selected.teacher', () => {
            $scope.selectedDict.teacher = $scope.selected.teacher;

            if ($scope.selectedDict.teacher != 'undefined') {
              $scope.$emit('selectedGradeSchool', {
                district: $scope.selectedDict.district || null,
                teacher: $scope.selectedDict.teacher ? [$scope.selectedDict.teacher.id] : null,
                school: $scope.selectedDict.school || null,
                grade: $scope.selectedDict.grade || null,
              })
            }
          });

        $scope.$watch('selected.district', () => {
          $scope.selectedDict.district = $scope.isSelected('district')
            ? $scope.selected.district
            : null;
        });
        
        $scope.isSelected = (what) => {
          if($scope.userrole === 'School Administrator' && what === 'school') {
            return true;
          } else {
            return angular.isObject($scope.selected[what]);
          }
        }

        $scope.getDistricts = () => $scope.isSelected('state')
          ? $scope.districts[$scope.selected.state.id]
          : [];

        $scope.getSchools = () => {
          return $scope.isSelected('district') ? $scope.schools[$scope.selected.district.id] : [];
        }

        $scope.getGrades = (schoolId = null) => {
          $scope.grades = [];
          $http.get('/grades/list' + (schoolId ? '?school_id=' + schoolId : '')).then(function (response) {
            $scope.grades = response.data;
          }, function (data) {
            console.error(data);
          });
        };

        $scope.getTeachers = (schoolId = null) => {
          $scope.teachers = $scope.schoolTeachers = [];
          $http.get('/teachers/list' + (schoolId ? '?school_id=' + schoolId : '')).then(function (response) {
            $scope.schoolTeachers = response.data;
            //if (!schoolId) {
              $scope.teachers = $scope.schoolTeachers;
            //}
          }, function (data) {
            console.error(data);
          });
        };

        if($scope.userrole === 'School Administrator') {
          $scope.getGrades();
        }
        if($scope.userrole === 'District Administrator') {
          $scope.setDistrictByID($scope.districtId);
          $scope.getSchools();
        }

        if($scope.userrole !== 'Teacher') {
          $scope.getTeachers();
        } else {
          $scope.selected.school = $scope.forceSchool;
          $scope.getGrades($scope.selected.school.id);
        }
      }
    }
  }
]);
