spApp.controller('ResourceController', [
  '$scope', '$http', '$uibModal', 'lodash', 'Resources.assignModal', 'alertProvider',
  function ($scope, $http, $uibModal, lodash, assignModal, alertProvider) {
    $scope.loaders = [];
    $scope.loading = true;
    $scope.resources = [];
    $scope.selectedDok = [];
    $scope.selectedLessonNumbers = [];
    $scope.selectedFocus = [];
    $scope.selectedLessonTypes = [];
    $scope.selectedResourceTypes = [];
    $scope.selectedPublisher = [];
    $scope.selectedStandard = [];
    $scope.selectedStrand = [];
    $scope.selectedSubject = [];
    $scope.selectedTitle = "";
    $scope.selectedGrade = null;

    const getPaginationOptions = () => ({
      perPage: 75,
      page: 1,
      lastPage: null
    })

    const resetResources = () => {
        $scope.resources = [];
        $scope.groupedResources = [];

        paginationOptions = getPaginationOptions();
    }

    let paginationOptions = getPaginationOptions();

    $scope.isAssessment = resource => resource.resource_lesson_types.some(item => item.title === 'Assessment');
    $scope.isEBook = resource => resource.resource_lesson_types.some(item => item.title === 'eBook');
    $scope.isPerformanceLesson = resource => typeof resource.resource_lesson_types !== 'undefined' ? resource.resource_lesson_types.some(item => item.title === 'Performance Lesson') : false;
    $scope.isReteachPdf = resource => typeof resource.resource_type !== 'undefined' ? resource.resource_type.title === 'PDF' && !$scope.isPerformanceLesson(resource) : false;

    $scope.itemType = function (resource) {
      if (resource.content.includes('.pdf')) {
        return 'pdfItem';
      } else {
        return 'lesson'
      }
    };

    $scope.clearFilter = () => {
      $scope.selectedTitle = "";
    };

    $scope.getData = options => {
      // wait until everything is loaded
      if ($scope.loaders.length > 0) {
        return;
      }

      const params = {
        perPage: options.perPage,
        selectedTitle: $scope.selectedTitle,
        'selectedDok[]': $scope.selectedDok,
        'selectedLessonNumbers[]': $scope.selectedLessonNumbers,
        'selectedFocus[]': $scope.selectedFocus,
        'selectedGrades[]': [$scope.selectedGrade].filter(i => i),
        'selectedLessonTypes[]': $scope.selectedLessonTypes,
        'selectedResourceTypes[]': $scope.selectedResourceTypes,
        'selectedPublisher[]': $scope.selectedPublisher,
        'selectedStandard[]': $scope.selectedStandard,
        'selectedStrand[]': $scope.selectedStrand,
        'selectedSubject[]': $scope.selectedSubject,
        page: options.page,
      };

      $scope.loading = true

      $http.get('/resources/filter', { params })
        .then(
          (response) => {
            paginationOptions = { ...paginationOptions, lastPage: response.data.last_page }
            const resources = response.data.data.map(resource => ({
              ...resource,
              resource_standards: {
                ...resource.resource_standards,
                displayText: lodash.join(
                  lodash.map(resource.resource_standards, 'title'),
                  ', '
                )
              }
            }))

            $scope.resources = [...$scope.resources, ...resources];

            $scope.groupedResources = lodash
              .chain($scope.resources)
              .groupBy(resource => resource.resource_strand_domain)
              .values()
              .value()
            $scope.loading = false
          },
          lodash.noop
        );
    };

    $scope.loadNextData = () => {
      const { page, lastPage } = paginationOptions

      if (!lastPage || page < lastPage) {
        paginationOptions = { ...paginationOptions, page: paginationOptions.page + 1 }

        $scope.getData(paginationOptions)
      }
    }

    $scope.showInPlayer = (resource) => {

      if (!shouldOpen(resource)) {
        return;
      }

      $uibModal.open({
        animation: true,
        templateUrl: '/views/player.html',
        controller: 'PlayerController',
        windowClass: 'player-modal-window',
        resolve: { resource }
      });
    };

    $scope.addAssignmentModal = (resource) => {

      if (!shouldOpen(resource)) {
        return;
      }

      assignModal(resource)
        .then(lodash.noop, lodash.noop);
    };

    $scope.init = ({ grades, trial }) => {
      $scope.grades = grades;
      $scope.trial = trial;
      if (!$scope.selectedGrade) {
        $scope.selectedGrade = grades.length
          ? grades[0].id
          : null;
      }

      const refresh = lodash.debounce(() => {
        resetResources()
        $scope.getData(paginationOptions)
      }, 500);

      const watchItems = [
        'selectedDok', 'selectedLessonNumbers', 'selectedFocus', 'selectedGrade',
        'selectedLessonTypes', 'selectedPublisher', 'selectedStandard', 'selectedStrand',
        'selectedSubject', 'selectedTitle', 'selectedResourceTypes', 'loaders'
      ];

      setTimeout(() => {
        watchItems.forEach((name) => {
          $scope.$watch(name, (newVal, oldVal) => {
            if (!angular.equals(newVal, oldVal)) {
              refresh();
            }
          });
        });
      }, 0);

      refresh();
    };

    const shouldOpen = resource => $scope.trial
      ? !!resource.trial
      :  true;

    $scope.delete = (id) => {
      $http.delete('/resources/' + id).then((response) => {
        alertProvider.responseSuccess(response);
        console.log(response);
        $scope.init(response.data.data);
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    }
    }
]);
