angular.module('spApp')
  .factory('routeProvider', function routeProviderFactory() {
    this.create = function (routeName, routeParams) {
      return Routing.generate(routeName, routeParams);
    };

    this.go = function (routeName, routeParams) {
      var route = this.create(routeName, routeParams);
      window.location.href = route;
    };

    this.goToRoute = function (route) {
      window.location.href = route;
    };
    return this;
  });