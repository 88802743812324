spApp.controller('NewdataStudentPopupController', [
  '$scope',
  '$uibModalInstance',
  'resource',
  '$sce',
  '$http',
  function ($scope, $uibModalInstance, resource, $sce, $http) {
    $scope.resource = resource;
    $scope.text = null;
    $scope.url = '/data/'+ resource.id +'/students/?iframe=1';
    $scope.pageUrl = '/data/'+ resource.id +'/students/';
    $scope.origTitle = document.title;
    $scope.origURL = window.location.href;

    window.history.pushState({}, $scope.resource.title, $scope.pageUrl);

    $scope.cancel = function () {
      window.history.pushState({}, $scope.origTitle, $scope.origURL);
      $uibModalInstance.dismiss();
    };
  }
]);
