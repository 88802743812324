spApp.controller('ResourceImportController', ['$scope', '$http', '$uibModal', function ($scope, $http, $uibModal) {

  const noop = () => {};
  $scope.lessons = {
    loading: false,
    data: [],
    cursor: null,
    more_count: 0,
  };

  $scope.sortableOptions = {
    placeholder: "resource-placeholder",
    connectWith: ".resources-sortable-list",
    update: function(e, ui) {
      if(ui.item.sortable.droptarget[0].id === "resources-sortable-list") {
        ui.item.sortable.cancel();
      }
    },
    stop: function (e, ui) {
      if(!ui.item.sortable.droptarget) {
        return;
      }

      const publicationIdString = ui.item.sortable.droptarget[0].id.split("-");
      const publicationId = publicationIdString[1];

      updateResourceOrder(publicationId);
    }
  };


  $scope.selectAll = {
    checked: false,
    check: (e) => {
      const checked = (e.target.checked)? true : false
      $scope.lessons.data = $scope.lessons.data.map((resource) => ({
        ...resource,
        checked,
      }))
    },
    bind: (e) => {
      $scope.selectAll.checked = ($scope.lessons.data.length > 0 && $scope.lessons.data.length == $scope.getNumberChecked());
    }
  };

  $scope.currentProject = {
    id: "",
    title: "",
    publications: [],
    index: 0
  };
  $scope.currentPublication = {
    id: "",
    title: "",
    index: 0
  };

  function updateResourceOrder(publicationId){
    $scope.lessons.data = $scope.lessons.data.map((resource, i) => ({
      ...resource,
      order: i
    }));

    const resources = $scope.lessons.data.map(({id}) => id);

    return $http.post(`/lessons/resources/import/${publicationId}/order`, {resources})
      .then(noop, noop);
  }

  $scope.getNumberChecked = () => {
    const filtered = $scope.lessons.data.filter(({checked}) => checked);
    return filtered.length;
  }

  $scope.getProjects = () => {

    $http.get('/lessons/resources/import/projects')
      .then((response) => {
        let projects = response.data.filter((el) => {
          return (
            el['title'].indexOf("Standards Plus") != -1 && (el['title'].indexOf("Math") != -1 || el['title'].indexOf("ELA") != -1)
            // el['title'].indexOf("Gateway2Achieve") != -1 && (el['title'].indexOf("Math") != -1 || el['title'].indexOf("ELA") != -1)
          );
        });

        projects = projects.sort(
          function(a, b){
            a = a.title;
            b = b.title;
            a = (a.slice(-1) == "K") ? a.substring(0, a.length - 1)+"0" : a;
            b = (b.slice(-1) == "K") ? b.substring(0, b.length - 1)+"0" : b;
            return (a > b) - (a < b);
          }
        );
        $scope.projects = projects;
    }, noop);
  };

  $scope.getPublications = (id, key) => {

    $scope.selectAll.bind();

    $scope.currentProject.id = id;
    $scope.currentProject.title = $scope.projects[key].title;
    $scope.currentProject.publications = [];
    $scope.currentProject.index = key;

    $scope.currentPublication.id = "";
    $scope.currentPublication.title = "";

    if(!$scope.projects[key].loaded){
      $scope.projects[key].loaded = false;
      $http.get('/lessons/resources/import/publications/'+id).then((response) => {
        let subspaces = response.data.subspaces;
        subspaces = subspaces.sort(
          function(a, b){
            a = a.name;
            b = b.name;
            return (a > b) - (a < b);
          }
        );
        $scope.projects[key].publications = subspaces;
        $scope.currentProject.publications = subspaces;
        $scope.projects[key].loaded = true;
      }, noop);
    }else{
      $scope.currentProject.publications = $scope.projects[key].publications;
    }
  };

  $scope.showLinkText = (count) => {
    return (count>49) ? "Load next 50" : "Load last "+count;
  };

  $scope.checkLesson = (lesson) => {
    //when click on checkbox it returns the same status as was before
    lesson.checked = (lesson.checked)? false : true;
    $scope.selectAll.bind();
  };

  $scope.getLessons = (id, project_key, publication_key) => {
    $scope.selectAll.checked = false;
    $scope.is_loading_more = ($scope.lessons.cursor != null && $scope.currentPublication.id == id)? true : false;

    // for using in "loading more" mode...
    $scope.currentPublication.id = $scope.projects[project_key].publications[publication_key].id;
    $scope.currentPublication.title = $scope.projects[project_key].publications[publication_key].name;
    $scope.currentProject.index = project_key;
    $scope.currentPublication.index = publication_key;

    $scope.lessons.loading = true;
    $scope.lessons.more_count = 0;


    if(!$scope.is_loading_more){
      $scope.lessons.data = [];
      // Prevent secondary downloading
      if($scope.projects[project_key].publications[publication_key].lessons){
        $scope.lessons.data = $scope.projects[project_key].publications[publication_key].lessons;
        $scope.lessons.cursor = $scope.projects[project_key].publications[publication_key].cursor;
        $scope.lessons.more_count = $scope.projects[project_key].publications[publication_key].more_count;

        $scope.lessons.loading = false;
      }
    }

    if($scope.lessons.loading || $scope.is_loading_more){
      $http.get(`/lessons/resources/import/lessons/${id}/${$scope.lessons.cursor}`)
        .then(({data}) => {
          const orderOffset = $scope.is_loading_more
            ? $scope.lessons.data.length
            : 0;
          
          let lessons_list = data.lessons;
          // sort by name if disabled order in the first item
          if(typeof data.lessons != 'undefined' && typeof data.lessons[0] != 'undefined' && typeof data.lessons[0].order != 'undefined' && data.lessons[0].order === null) {
            lessons_list = lessons_list.sort(
              function(a, b){
                a = a.title;
                b = b.title;
                return (a > b) - (a < b);
              }
            );
          }

          const mappedLessons = data.lessons.map((resource, i) => ({
            ...resource,
            checked: false,
            // the api sets `order` only when publications were sorted manually,
            // for a unsorted publication this field will be null
            // in such case just use the position in the array
            order: resource.order || (orderOffset + i),
          }));

          const lessons = ($scope.is_loading_more)
            ? $scope.lessons.data.concat(mappedLessons)
            : mappedLessons;

          $scope.projects[project_key].publications[publication_key].lessons = lessons;
          $scope.projects[project_key].publications[publication_key].more_count = data.more_count;
          $scope.projects[project_key].publications[publication_key].cursor = data.cursor;

          $scope.lessons.cursor = data.cursor;
          $scope.lessons.more_count = data.more_count;
          $scope.lessons.loading = false;
          $scope.lessons.data = lessons;
      }, noop);
    }
    $scope.selectAll.bind();
  };

  $scope.import_progressbar_width = 1;
  var $uibModalInstance;
  $scope.dismiss = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.importLessons = () => {
    const lessons = $scope.lessons.data.filter(({checked}) => checked);

    if(!lessons.length){
      return;
    }

    const data = {
      publication: $scope.currentPublication,
      lessons,
    };

    $uibModalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/views/import_modal.html',
      scope: $scope,
      backdrop  : 'static',
      keyboard  : false
    });

    $uibModalInstance.result.then(noop, noop);

    $uibModalInstance.opened.then(function(){
      var i = 0;
      var countdownTimer = setInterval(function() {
          i = i + 1;
          if (i >= 25) {
            $scope.import_progressbar_width = 100;
            clearInterval(countdownTimer);
          }else{
            $scope.import_progressbar_width = i*4;
          }

          $scope.$apply($scope.import_progressbar_width);
      }, 2000);
    });

    $http.post('/lessons/resources/import/save', {data})
      .then(noop, noop);
  };

  $scope.init = () => {
    $scope.getProjects();
  };
}]);
