spApp.directive('teacherDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/teachers-filter.html',
    scope: {
      selectedStates: '=',
      selectedDistricts: '=',
      selectedSchools: '=',
      selectedSubject: '=',
      selectedGrades: '=',
      selectedTeachers: '='
    },
    link: function ($scope) {
      $scope.$watchGroup(['selectedStates', 'selectedDistricts', 'selectedSchools', 'selectedSubject', 'selectedGrades'], () => {
        $scope.getTeacherData();
      });

      $scope.getTeacherData = () => {
        const params = {
          'states[]': $scope.selectedStates,
          'districts[]': $scope.selectedDistricts,
          'schools[]': $scope.selectedSchools,
          'subjects[]': $scope.selectedSubject,
          'grades[]': $scope.selectedGrades
        };

        $http.get('/teachers/list', {params: params}).then(function (response) {
          $scope.teachers = response.data;
        }, function (data) {
          console.error(data);
        });      
      }

      $scope.teachersChanged = function () {
        $scope.selectedTeachers = $scope.teachers.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
