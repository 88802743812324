spApp.controller('RubricController', ['$scope', '$http', '$uibModal', 'alertProvider', '$location', function ($scope, $http, $uibModal, alertProvider, $location) {
    $scope.rubrics = [];
    $scope.resources = [];
    $scope.selectedGrades = [];
    $scope.selectedSubject = [];
    $scope.paging = {
      perPage: 25,
      from: 1,
      to: 25,
      total: 0,
      currentPage: 0,
      lastPage: 0
    };
    $scope.filters = {
      title: ''
    };

    $scope.goTo = (page) => {
      $scope.getData(page);
    };

    $scope.$watchGroup(['selectedGrades', 'selectedSubject'], () => {
      $scope.getData();
    });

    $scope.getData = (page) => {

      let params = {
        title: $scope.filters.title,
        perPage: $scope.paging.perPage,
        'selectedGrades[]': $scope.selectedGrades,
        'selectedSubject[]': $scope.selectedSubject
      };
      page ? params.page = page : true;
      $http.get('/rubrics/filter', {params: params}).then((response) => {
        $scope.rubrics = response.data.data;
        $scope.getPagingData(response.data);
      }, (data) => {
        console.error(data);
      });

    };
    $scope.deleteRubric = (id) => {


      $http.delete('/rubrics/' + id).then((response) => {
        alertProvider.success('Rubric removed.');
        $scope.getData();
      }).catch((response) => {
        alertProvider.responseError(response);
      });
    };

    $scope.getPagingData = (data) => {
      $scope.paging.from = angular.copy(data.from);
      $scope.paging.to = angular.copy(data.to);
      $scope.paging.total = angular.copy(data.total);
      $scope.paging.currentPage = angular.copy(data.current_page);
      $scope.paging.lastPage = angular.copy(data.last_page);
    };

    $scope.addRubricModal = () => {
      let modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/views/add-rubric.html',
        controller: 'RubricAddController',
        size: 'lg'

      });

      modalInstance.result.then(() => {

      }, () => {

      });
    };

    $scope.init = () => {
      $scope.getData();
    };
  }]);
