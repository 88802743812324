spApp.controller('HomeController', ['$scope', '$http', function ($scope, $http) {

    $scope.getData = () => {
      $http.get('/student/teachers').then((response) => {
        $scope.teachers = response.data;
      }, (data) => {
        console.error(data);
      });
    };
    $scope.init = () => {
      $scope.getData();
    };
  }]);