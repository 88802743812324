spApp.controller('NewdataStudentController', ['$scope', '$http', 'alertProvider', '$uibModal', function ($scope, $http, alertProvider, $uibModal) {
  $scope.assignmentStudents = [];
  $scope.loading = false;
  
  $scope.selectedDok = [];
  $scope.selectedFocus = [];
  $scope.selectedGrades = [];
  $scope.selectedLessonTypes = [];
  $scope.selectedStandard = [];
  $scope.selectedStrand = [];
  $scope.selectedSubject = [];
  $scope.selectedSchools = [];
  $scope.selectedDistricts = [];
  $scope.selectedTeachers = [];
  $scope.selectedStates = [];
  $scope.selectedScored = [];
  $scope.selectedDataGroups = [];
  $scope.selectedInterventionGroups = [];
  $scope.fromDate = null;
  $scope.toDate = null;
  $scope.format = 'dd-MMMM-yyyy';
  $scope.altInputFormats = ['M!/d!/yyyy'];
  $scope.assignmentsStudents = [];
  $scope.assignment = [];

  $scope.$watchGroup(['selectedDok', 'selectedLessonNumbers', 'selectedFocus', 'selectedGrades',
    'selectedLessonTypes', 'selectedPublisher', 'selectedStandard', 'selectedStrand', 'selectedSubject',
    'selectedDueDate', 'selectedScored', 'selectedSchools', 'selectedDistricts', 'selectedTeachers',
    'selectedStates', 'selectedDataGroups', 'selectedInterventionGroups'], () => {
    $scope.getData();
  });

  $scope.init = (id, assignment) => {
    $scope.id = id;
    $scope.assignment = assignment;
    //$scope.getData(id);
  };

  $scope.getData = (id) => {
    let fromDate, toDate;
    if($scope.fromDate) {
      let fromMonth = $scope.fromDate.getMonth();
      fromDate = moment([$scope.fromDate.getFullYear(), fromMonth, $scope.fromDate.getDate(), 0]).format('LLLL');
    }
    if($scope.toDate) {
      let toMonth = $scope.toDate.getMonth();
      toDate = moment([$scope.toDate.getFullYear(), toMonth, $scope.toDate.getDate(), 0]).format('LLLL');
    }

    let params = {
      fromDate: fromDate,
      toDate: toDate,
      'selectedScored[]': $scope.selectedScored,
      'selectedDok[]': $scope.selectedDok,
      'selectedLessonNumbers[]': $scope.selectedLessonNumbers,
      'selectedFocus[]': $scope.selectedFocus,
      'selectedGrades[]': $scope.selectedGrades,
      'selectedLessonTypes[]': $scope.selectedLessonTypes,
      'selectedPublisher[]': $scope.selectedPublisher,
      'selectedStandard[]': $scope.selectedStandard,
      'selectedStrand[]': $scope.selectedStrand,
      'selectedSubject[]': $scope.selectedSubject,
      'selectedStates[]':$scope.selectedStates,
      'selectedDistricts[]':$scope.selectedDistricts,
      'selectedSchools[]':$scope.selectedSchools,
      'selectedTeachers[]':$scope.selectedTeachers,
      'selectedGroups[]':$scope.selectedDataGroups,
      'selectedInterventionGroups[]':$scope.selectedInterventionGroups
    };
    $scope.loading = true;

    $http.get('/data/'+$scope.id+'/students/list', {params: params}).then((response) => {
      $scope.assignments = response.data;
    }, (data) => {
      alertProvider.responseError(data);
    }).finally(() => {
      $scope.loading = false;
    });
  };

  $scope.fromDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(),
    startingDay: 1
  };

  $scope.toDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.fromDateChange = () => {
    $scope.toDateOptions.minDate = $scope.fromDate;
    $scope.getData();
  };

  $scope.toDateChange = () => {
    $scope.fromDateOptions.maxDate = $scope.toDate;
    $scope.getData();
  };

  $scope.fromDateOpen = () => {
    $scope.fromDatePopup.opened = true;
  };

  $scope.toDateOpen = () => {
    $scope.toDatePopup.opened = true;
  };

  $scope.fromDatePopup = {
    opened: false
  };

  $scope.toDatePopup = {
    opened: false
  };
}]);