spApp.controller('DataController', ['$scope', '$http', 'routeProvider', function ($scope, $http, routeProvider) {
  $scope.params = null;
  $scope.selectedGrades = [];
  $scope.selectedSchools = [];
  $scope.selectedDistricts = [];
  $scope.selectedTeachers = [];
  $scope.selectedStates = [];
  $scope.selectedStudent = "";
  $scope.fromDate = null;
  $scope.toDate = null;
  $scope.format = 'dd-MMMM-yyyy';
  $scope.altInputFormats = ['M!/d!/yyyy'];

  $scope.$watchGroup(['selectedDok', 'selectedLessonNumbers', 'selectedFocus', 'selectedGrades',
    'selectedLessonTypes', 'selectedPublisher', 'selectedStandard', 'selectedStrand', 'selectedSubject',
    'selectedTitle', 'selectedScored', 'selectedStudent', 'selectedSchools', 'selectedDistricts', 'selectedTeachers',
    'selectedStates'], () => {
    $scope.setParams();
  });

  $scope.setParams = () => {
    let fromDate, toDate;
    if($scope.fromDate) {
      let fromMonth = $scope.fromDate.getMonth();
      fromDate = moment([$scope.fromDate.getFullYear(), fromMonth, $scope.fromDate.getDate(), 0]).format('LLLL');
    }
    if($scope.toDate) {
      let toMonth = $scope.toDate.getMonth();
      toDate = moment([$scope.toDate.getFullYear(), toMonth, $scope.toDate.getDate(), 0]).format('LLLL');
    }

    $scope.params = {
      selectedStudent: $scope.selectedStudent ? $scope.selectedStudent.title : '',
      fromDate: fromDate,
      toDate: toDate,
      'selectedGrades[]': $scope.selectedGrades,
      'selectedStates[]':$scope.selectedStates,
      'selectedDistricts[]':$scope.selectedDistricts,
      'selectedSchools[]':$scope.selectedSchools,
      'selectedTeachers[]':$scope.selectedTeachers,
    };
  };

  $scope.getStudentsData = () => {
    $http.get('/users/students').then((response) => {
      $scope.students = response.data;
    }, (data) => {
      console.error(data);
    });
  };

  $scope.init = () => {
    $scope.getStudentsData();
  };

  $scope.fromDateOptions = {
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    startingDay: 1
  };

  $scope.toDateOptions = {
    formatYear: 'yy',
    minDate: new Date(),
    startingDay: 1
  };

  $scope.fromDateChange = () => {
    $scope.toDateOptions.minDate = $scope.fromDate;
    $scope.setParams();
  };

  $scope.toDateChange = () => {
    $scope.fromDateOptions.maxDate = $scope.toDate;
    $scope.setParams();
  };

  $scope.fromDateOpen = () => {
    $scope.fromDatePopup.opened = true;
  };

  $scope.toDateOpen = () => {
    $scope.toDatePopup.opened = true;
  };

  $scope.fromDatePopup = {
    opened: false
  };

  $scope.toDatePopup = {
    opened: false
  };

  $scope.clearNameFilter = () => {
    $scope.$broadcast('angucomplete-alt:clearInput', 'name-filter');
  };

  $scope.checkTeacher = (user,type) => {
    if(user.role=='Teacher'){
      let subject  = user.subjects_conducted.filter(item => item.title==type);
      if(subject.length){
        return true;
      }
      return false;
    }
    return true;
  }
}]);
