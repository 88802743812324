spApp.directive('resourceTypeDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/resource-type-filter.html',
    scope: {
      selectedResourceTypes: '='
    },
    link: function ($scope) {
      $http.get('/resources/resourceTypes').then(function (response) {
        $scope.resourceTypes = response.data;
      }, function (data) {
        console.error(data);
      });
      $scope.typeChange = function () {
        $scope.selectedResourceTypes = $scope.resourceTypes.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
