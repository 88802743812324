spApp.directive('gradeDirective', ['$http', function ($http) {
  return {
    restrict: 'E',
    templateUrl: '/views/filters/grade-filter.html',
    scope: {
      selectedGrades: '='
    },
    link: function ($scope) {
      $http.get('/grades/list').then(function (response) {
        $scope.grades = response.data;
      }, function (data) {
        console.error(data);
      });
      $scope.gradeChanged = function () {
        $scope.selectedGrades = $scope.grades.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.id;
        });
      };
    }
  };
}]);
